import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import {
  FormControl, Tooltip, Select, MenuItem,
} from '@material-ui/core';
import CounterBalancingTypes from './CounterBalancingTypes';
import { blockSessionProps } from './PropTypes';
import {
  changeCounterBalancingOfBlockAction,
  changeReplicationsAction,
  changeSerialOfBlockAction,
} from '../../store/experimentalCanvas/block/BlockAction';

class BlockSession extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sessionId: PropTypes.string.isRequired,
    blockId: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    session: PropTypes.shape(blockSessionProps).isRequired,
    index: PropTypes.number,
    experimentalDesignId: PropTypes.string,
  };

  static defaultProps = {
    index: undefined,
    experimentalDesignId: undefined,
  }

  changeCounterBalancingHandler = (e) => {
    this.changeCounterBalancingOfBlock(e.target.value);
  };

  changeReplicationsHandler = (e) => {
    this.changeReplicationsOfBlock(e.target.value);
  }

  changeSerialHandler = (e) => {
    this.changeSerialOfBlock(e.target.value);
  };

  changeCounterBalancingOfBlock(
    newCounterBalancingMethod,
  ) {
    const {
      dispatch, experimentalDesignId, blockId, sessionId,
    } = this.props;
    dispatch(
      changeCounterBalancingOfBlockAction(
        experimentalDesignId,
        blockId,
        sessionId,
        newCounterBalancingMethod,
      ),
    );
  }

  changeReplicationsOfBlock(newReplications) {
    const {
      dispatch, experimentalDesignId, blockId, sessionId,
    } = this.props;
    dispatch(changeReplicationsAction(experimentalDesignId, blockId, sessionId, newReplications));
  }

  changeSerialOfBlock(serial) {
    const {
      dispatch,
      experimentalDesignId,
      blockId,
      sessionId,
    } = this.props;

    // TODO: fix this ugly hack
    let newSerial = true;
    if (serial === 'true') {
      newSerial = false;
    }
    dispatch(changeSerialOfBlockAction(experimentalDesignId, blockId, sessionId, newSerial));
  }

  CounterBalancingRender() {
    const { index, session } = this.props;
    return (
      <div styles="float: right">
        <form>

          <FormControl>
            <Tooltip placement="top" title="Counter Balancing Method">
              <Select
                value={session.counterBalancingMethod}
                onChange={this.changeCounterBalancingHandler}
                inputProps={{
                  name: 'counter-balanding',
                  id: 'counter-balanding',
                }}
              >
                {Object.keys(CounterBalancingTypes).map((key) => {
                  if (key === 'BETWEEN' && index === 0) {
                    return (
                      <MenuItem value={key} key={key}>
                        {' '}
                        {CounterBalancingTypes[key]}
                      </MenuItem>
                    );
                  }
                  if (key !== 'BETWEEN' || index === undefined) {
                    return (
                      <MenuItem value={key} key={key}>
                        {CounterBalancingTypes[key]}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </Tooltip>
          </FormControl>
        </form>
      </div>
    );
  }

  ReplicationsRender() {
    const { session } = this.props;
    const { replications } = session;
    const replicationsArray = [1, 2, 3, 4, 5];
    return (
      <div styles="float: right">
        <form>
          <FormControl>
            <Tooltip placement="top" title="Replication">
              <Select
                value={replications}
                onChange={this.changeReplicationsHandler}
                inputProps={{
                  name: 'replications',
                  id: 'replications',
                }}
              >
                {replicationsArray.map(key => (
                  <MenuItem value={key} key={key}>{key}</MenuItem>
                ))}
              </Select>
            </Tooltip>
          </FormControl>
        </form>
      </div>
    );
  }

  SerialRender() {
    const { session } = this.props;
    const { serial } = session;
    const serialBoolean = Boolean(serial);
    return (
      <div>
        <Tooltip placement="top" title="Serial">
          <Switch
            checked={serialBoolean}
            onChange={this.changeSerialHandler}
            disableRipple
            value={serialBoolean}
          />
        </Tooltip>
      </div>
    );
  }

  render() {
    return (
      <div className="block-session">
        {this.CounterBalancingRender()}
        {this.ReplicationsRender()}
        {this.SerialRender()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    zoomFactor: state.zoom.zoomFactor,
  };
}

export default connect(mapStateToProps)(BlockSession);
