const uuidv4 = require('uuid/v4');

function removeSessionsFromBlocks(sessionId, blocks) {
  return blocks.map((block) => {
    const newIndependentVariables = block.independentVariables.map((iv) => {
      const newLevels = iv.levels.map((level) => {
        const newSessions = level.sessions.filter((session) => {
          if (session.id !== sessionId) return session;
          return undefined;
        });
        return { ...level, sessions: newSessions };
      });
      return { ...iv, levels: newLevels };
    });

    if (block.sessions.length > 1) {
      // TODO: remove controls from block
      const newSessions = block.sessions.filter((session) => {
        if (session.id !== sessionId) return session;
        return undefined;
      });
      return { ...block, sessions: newSessions, independentVariables: newIndependentVariables };
    }

    return { ...block, independentVariables: newIndependentVariables };
  });
}

export function addSessionToExperimentalDesign(eds, experimentalDesignId) {
  const newEds = eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      const sessionId = `session_${uuidv4()}`;
      const edSessions = ed.sessions.map(session => session);
      const unequalLevelId = `unequalLevel_${uuidv4()}`;
      const newSession = {
        id: sessionId,
        title: 'NEW_SESSIO',
        repititions: 1,
        unequalLevels: [
          unequalLevelId,
        ],
      };
      edSessions.push(newSession);

      const newBlocks = ed.blocks.map((block) => {
        const newIndependentVariables = block.independentVariables.map((iv) => {
          const newLevels = iv.levels.map((level) => {
            const newSessions = level.sessions.map(s => s);
            const sessionUnequalLevelId = `session_unequal_level_${uuidv4()}`;
            const levelSession = {
              id: sessionId,
              unequalLevels: [
                {
                  id: sessionUnequalLevelId,
                  unequalLevelId,
                  sessionUnequalLevelValue: false,
                },
              ],
            };
            newSessions.push(levelSession);

            return { ...level, sessions: newSessions };
          });
          return { ...iv, levels: newLevels };
        });
        let newBlockSessions = block.sessions.map(session => session);

        if (edSessions.length === 1) newBlockSessions = [];

        const newBlockSession = {
          id: sessionId,
          counterBalancingMethod: 'LATIN_SQUARE',
          replications: 1,
          serial: true,
        };
        newBlockSessions.push(newBlockSession);
        return {
          ...block,
          independentVariables: newIndependentVariables,
          sessions: newBlockSessions,
        };
      });
      return { ...ed, sessions: edSessions, blocks: newBlocks };
    }
    return ed;
  });

  return newEds;
}

export function removeSessionFromExperimentalDesign(eds, experimentalDesignId, sessionId) {
  const newEds = eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      const newSessions = ed.sessions.filter((session) => {
        if (session.id !== sessionId) return session;
        return undefined;
      });
      const updatedBlocks = removeSessionsFromBlocks(sessionId, ed.blocks);
      return { ...ed, sessions: newSessions, blocks: updatedBlocks };
    }
    return ed;
  });

  return newEds;
}
