import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { blockProps } from './PropTypes';
import ItemTypes from './ItemTypes';
import IndependentVariable from './IndependentVariable';
import BlockSession from './BlockSession';
import {
  addNewIndependentVariableAction,
  moveIndependentVariableAction,
} from '../../store/experimentalCanvas/independentVariable/IndependentVariableAction';
import {
  sortBlockAction,
} from '../../store/experimentalCanvas/block/BlockAction';

class Block extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    block: PropTypes.shape(blockProps).isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    experimentalDesignId: PropTypes.string,
    blockClassName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    edSessions: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    sortIvToNewIndexInList: PropTypes.func,
    index: PropTypes.number,
  };

  static defaultProps = {
    index: undefined,
    experimentalDesignId: undefined,
    sortIvToNewIndexInList: undefined,
    edSessions: [],
  }

  addNewIndependentVariable() {
    const { dispatch, experimentalDesignId, id } = this.props;
    dispatch(
      addNewIndependentVariableAction(
        experimentalDesignId,
        id,
        undefined,
        undefined,
      ),
    );
  }

  moveIndependentVariable(sourceExperimentalDesignId, sourceBlockId, independentVariable) {
    const { id, experimentalDesignId, dispatch } = this.props;
    dispatch(moveIndependentVariableAction(
      experimentalDesignId,
      id,
      sourceExperimentalDesignId,
      sourceBlockId,
      independentVariable,
    ));
  }

  sortBlock(blockId, newIndex) {
    const { dispatch, experimentalDesignId } = this.props;
    dispatch(sortBlockAction(experimentalDesignId, blockId, newIndex));
  }

  IvsRender(independentVariables, blockId, experimentalDesignId) {
    const { connectDropTarget, sortIvToNewIndexInList, edSessions } = this.props;
    const ivs = independentVariables.map((iv, i) => (
      <IndependentVariable
        key={iv.id}
        id={iv.id}
        independentVariableId={iv.id}
        independentVariable={iv}
        edSessions={edSessions}
        index={i}
        experimentalDesignId={experimentalDesignId}
        blockId={blockId}
        itemType={ItemTypes.INDEPENDENT_VARIABLE}
        independentVariableClassName="independent-variable-in-block"
        sortIvToNewIndexInList={(
          independentVariableId,
          newIndex,
        ) => sortIvToNewIndexInList(
          experimentalDesignId,
          blockId,
          independentVariableId,
          newIndex,
        )
          }
      />
    ));

    return connectDropTarget(
      <div className="block__drop-zone">
        You can drag the iv here
        {ivs}
      </div>,
    );
  }

  blockSessionsRender(sessions) {
    const { experimentalDesignId, id, index } = this.props;
    const sessionsRender = sessions.map(session => (
      <BlockSession
        id={session.id}
        key={session.id}
        experimentalDesignId={experimentalDesignId}
        blockId={id}
        sessionId={session.id}
        session={session}
        index={index}
      />
    ));

    return (
      <div className="block__block-selectors">
        <div className="block__block-selectors__left-whitespace" />
        {sessionsRender}
      </div>
    );
  }

  render() {
    const {
      connectDragSource,
      isDragging,
      experimentalDesignId,
      block,
      id,
      blockClassName,
    } = this.props;

    const opacity = isDragging ? 0 : 1;
    const { top, left } = block;

    return connectDragSource(
      <div style={{ opacity, left, top }} className={blockClassName}>
        {this.blockSessionsRender(block.sessions)}
        {this.IvsRender(block.independentVariables, id, experimentalDesignId)}
      </div>,
    );
  }
}

const blockSource = {
  beginDrag(props) {
    const {
      id, block, experimentalDesignId, index, itemType,
    } = props;
    // this message can be accessed in ExperimentalDesign blockTarget drop
    const message = 'Ciao from Block begin Drag Alex';
    return {
      id, block, experimentalDesignId, message, index, itemType,
    };
  },
};

const blockTarget = {
  drop(props, monitor, component) {
    if (!component) {
      return;
    }
    const blockComponent = component.getDecoratedComponentInstance();
    const {
      experimentalDesignId,
      blockId,
      independentVariable,
    } = monitor.getItem();
    const sourceBlockId = blockId;
    const sourceExperimentalDesignId = experimentalDesignId;

    // if the iv comes from the same block stop, this is needed later for sorting
    if (sourceBlockId === props.id || independentVariable === undefined) return;
    // IV is new
    if (independentVariable.new) {
      blockComponent.addNewIndependentVariable();
      return;
    }

    if (!independentVariable.new) {
      independentVariable.left = undefined;
      independentVariable.top = undefined;
      blockComponent.moveIndependentVariable(
        sourceExperimentalDesignId,
        sourceBlockId,
        independentVariable,
      );
    }
  },
  hover(props, monitor, component) {
    // Is the block in an experimental design?
    if (!component) return;
    const item = monitor.getItem();
    const { itemType } = item;

    if (itemType !== ItemTypes.BLOCK) return;
    if (item.experimentalDesignId === undefined) return;

    // do nothing when coming from another experimental design
    // TODO: Start here when intending to make blocks sort in list of another experimental design;
    if (props.experimentalDesignId !== item.experimentalDesignId) return;

    const dragIndex = item.index; // the dragged item
    const hoverIndex = props.index; // the not-dragged item

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      findDOMNode(component).style.opacity = 0;
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    if (hoverIndex === 0 && props.block.counterBalancingMethod === 'BETWEEN') {
      // TODO: Loop
      component.changeCounterBalancingOfBlock(
        props.experimentalDesignId,
        props.id,
        'LATIN_SQUARE',
      );
    }
    if (dragIndex === 0 && item.block.counterBalancingMethod === 'BETWEEN') {
      // TODO: Loop
      component.changeCounterBalancingOfBlock(
        item.experimentalDesignId,
        item.id,
        'LATIN_SQUARE',
      );
    }

    component.sortBlock(item.id, hoverIndex);
    item.index = hoverIndex;
  },
};

// eslint-disable-next-line no-shadow
function collectBlock(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

// eslint-disable-next-line no-shadow
function collectObjects(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function mapStateToProps(state) {
  return {
    zoomFactor: state.zoom.zoomFactor,
  };
}

export default connect(mapStateToProps)(
  flow(
    DragSource(ItemTypes.BLOCK, blockSource, collectBlock),
    DropTarget(
      [ItemTypes.BLOCK, ItemTypes.INDEPENDENT_VARIABLE],
      blockTarget,
      collectObjects,
    ),
  )(Block),
);
