export function changeCounterBalancingMethodInBlocks(
  blocks,
  blockId,
  sessionId,
  newCounterBalancingMethod,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      const newSessions = b.sessions.map((session) => {
        if (session.id === sessionId || session.id === undefined) {
          return { ...session, counterBalancingMethod: newCounterBalancingMethod };
        }
        return session;
      });
      return { ...b, sessions: newSessions };
    }
    return b;
  });
}

export function changeCounterBalancingMethodInExperimentalDesigns(
  eds,
  experimentalDesignId,
  blockId,
  sessionId,
  newCounterBalancingMethod,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: changeCounterBalancingMethodInBlocks(
          ed.blocks,
          blockId,
          sessionId,
          newCounterBalancingMethod,
        ),
      };
    }
    return ed;
  });
}
