import { all, fork } from 'redux-saga/effects';
import zoomSaga from './zoomExperimentalDesign/ZoomSaga';
import { experimentalSaga } from './experimentalCanvas';

export default function* root() {
  yield all([
    fork(zoomSaga),
    fork(experimentalSaga),
  ]);
}
