import { takeEvery, put, select } from 'redux-saga/effects';
import {
  LEVEL_ADD_NEW_LEVEL_START,
  LEVEL_ADD_NEW_LEVEL_SUCCESS,
  LEVEL_MOVE_LEVEL_START,
  LEVEL_MOVE_LEVEL_SUCCESS,
  LEVEL_DELETE_LEVEL_START,
  LEVEL_DELETE_LEVEL_SUCCESS,
  LEVEL_SORT_LEVEL_START,
  LEVEL_SORT_LEVEL_SUCCESS,
  LEVEL_RENAME_LEVEL_START,
  LEVEL_RENAME_LEVEL_SUCCESS,
} from './LevelAction';
import {
  getAllExperimentalDesigns,
  getAllBlocks,
  getAllIndependentVariables,
  getAllLevels,
} from '../selector';
import {
  addLevelToBlock,
  addLevelToExperimentalDesigns,
  addLevelToIndependetVariables,
  addLevelToLevels,
} from './LevelHelperAdd';
import {
  removeLevelFromLevels,
  removeLevelFromIvs,
  removeLevelFromBlocks,
  removeLevelFromEds,
} from './LevelHelperRemove';
import {
  sortLevelFromBlocks,
  sortLevelFromEds,
  sortLevelFromIvs,
} from './LevelHelperSort';
import {
  renameLevelInLevels,
  renameLevelInIndependentVariables,
  renameLevelInBlocks,
  renameLevelInEds,
} from './LevelHelperRename';

const uuidv4 = require('uuid/v4');

function* addNewLevel(action) {
  const {
    experimentalDesignId,
    blockId,
    independentVariableId,
    left,
    top,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const independentVariables = yield select(getAllIndependentVariables);
  const levels = yield select(getAllLevels);

  let levelId = 'level_';
  levelId += uuidv4();

  const newLevel = {
    id: levelId,
    title: 'LEVEL',
    left,
    top,
  };

  yield put({
    type: LEVEL_ADD_NEW_LEVEL_SUCCESS,
    levels: addLevelToLevels(newLevel, levels, left, top),
    independentVariables: addLevelToIndependetVariables(
      newLevel,
      independentVariables,
      independentVariableId,
    ),
    blocks: addLevelToBlock(newLevel, blocks, blockId, independentVariableId),
    experimentalDesigns: addLevelToExperimentalDesigns(
      newLevel,
      eds,
      experimentalDesignId,
      blockId,
      independentVariableId,
    ),
  });
}

function* moveLevel(action) {
  const {
    targetEdId,
    targetBlockId,
    targetIvId,
    sourceEdId,
    sourceBlockId,
    sourceIvId,
    level,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const independentVariables = yield select(getAllIndependentVariables);
  const levels = yield select(getAllLevels);

  const tempLevels = removeLevelFromLevels(levels, level.id);
  const newLevels = addLevelToLevels(level, tempLevels);

  const tempIvs = removeLevelFromIvs(independentVariables, sourceIvId, level.id);
  const newIvs = addLevelToIndependetVariables(level, tempIvs, targetIvId);

  const tempBlocks = removeLevelFromBlocks(blocks, sourceBlockId, sourceIvId, level.id);
  const newBlocks = addLevelToBlock(level, tempBlocks, targetBlockId, targetIvId);

  const tempEds = removeLevelFromEds(eds, sourceEdId, sourceBlockId, sourceIvId, level.id);
  const newEds = addLevelToExperimentalDesigns(
    level,
    tempEds,
    targetEdId,
    targetBlockId,
    targetIvId,
  );

  yield put({
    type: LEVEL_MOVE_LEVEL_SUCCESS,
    levels: newLevels,
    independentVariables: newIvs,
    blocks: newBlocks,
    experimentalDesigns: newEds,
  });
}

function* deleteLevel(action) {
  const {
    experimentalDesignId,
    blockId,
    independentVariableId,
    levelId,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const independentVariables = yield select(getAllIndependentVariables);
  const levels = yield select(getAllLevels);

  yield put({
    type: LEVEL_DELETE_LEVEL_SUCCESS,
    levels: removeLevelFromLevels(levels, levelId),
    independentVariables: removeLevelFromIvs(independentVariables, independentVariableId, levelId),
    blocks: removeLevelFromBlocks(blocks, blockId, independentVariableId, levelId),
    experimentalDesigns: removeLevelFromEds(
      eds,
      experimentalDesignId,
      blockId,
      independentVariableId,
      levelId,
    ),
  });
}

function* sortLevel(action) {
  const {
    experimentalDesignId,
    blockId,
    independentVariableId,
    levelId,
    newIndex,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const independentVariables = yield select(getAllIndependentVariables);

  yield put({
    type: LEVEL_SORT_LEVEL_SUCCESS,
    independentVariables: sortLevelFromIvs(
      independentVariables,
      independentVariableId,
      levelId,
      newIndex,
    ),
    blocks: sortLevelFromBlocks(blocks, blockId, independentVariableId, levelId, newIndex),
    experimentalDesigns: sortLevelFromEds(
      eds,
      experimentalDesignId,
      blockId,
      independentVariableId,
      levelId,
      newIndex,
    ),
  });
}

function* renameLevel(action) {
  const {
    experimentalDesignId,
    blockId,
    independentVariableId,
    levelId,
    newName,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const independentVariables = yield select(getAllIndependentVariables);
  const levels = yield select(getAllLevels);

  yield put({
    type: LEVEL_RENAME_LEVEL_SUCCESS,
    levels: renameLevelInLevels(levels, levelId, newName),
    independentVariables: renameLevelInIndependentVariables(
      independentVariables,
      independentVariableId,
      levelId,
      newName,
    ),
    blocks: renameLevelInBlocks(blocks, blockId, independentVariableId, levelId, newName),
    experimentalDesigns: renameLevelInEds(
      eds,
      experimentalDesignId,
      blockId,
      independentVariableId,
      levelId,
      newName,
    ),
  });
}

const levelSaga = function* () {
  yield takeEvery(LEVEL_ADD_NEW_LEVEL_START, addNewLevel);
  yield takeEvery(LEVEL_MOVE_LEVEL_START, moveLevel);
  yield takeEvery(LEVEL_DELETE_LEVEL_START, deleteLevel);
  yield takeEvery(LEVEL_SORT_LEVEL_START, sortLevel);
  yield takeEvery(LEVEL_RENAME_LEVEL_START, renameLevel);
};

export default levelSaga;
