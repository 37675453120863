export function removeBlockFromBlocks(blocks, blockId) {
  return blocks.filter((b) => {
    if (b.id !== blockId) return b;
    return undefined;
  });
}

export function removeBlockFromExperimentalDesigns(
  eds,
  experimentalDesignId,
  blockId,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: removeBlockFromBlocks(ed.blocks, blockId),
      };
    }
    return ed;
  });
}
