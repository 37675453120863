import React, { Component } from 'react';
import { CirclePicker } from 'react-color';
import { Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import { changeColourOfExperimentalDesignAction } from '../../store/experimentalCanvas/experimentalDesign/ExperimentalDesignAction';

class ExperimentalDesignColourPicking extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    experimentalDesignId: PropTypes.string.isRequired,
    colour: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  }

  changeColourOfExperimentalDesign = (newColour) => {
    const { dispatch, experimentalDesignId } = this.props;
    if (newColour.hex !== undefined) {
      dispatch(changeColourOfExperimentalDesignAction(experimentalDesignId, newColour.hex));
      this.setState({
        anchorEl: null,
      });
    }
  }

  render() {
    const { colour } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : null;

    return (
      <div className="experimental-design-colour-picking">
        <div
          className="experimental-design-colour-picking__dot"
          style={{ backgroundColor: colour }}
          onClick={this.handleClick}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <CirclePicker
            color={colour}
            onChangeComplete={this.changeColourOfExperimentalDesign}
          />
        </Popover>
      </div>
    );
  }
}

export default ExperimentalDesignColourPicking;
