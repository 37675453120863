export function changeReplicationsInBlocks(
  blocks,
  blockId,
  sessionId,
  newReplications,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      const newSessions = b.sessions.map((session) => {
        if (session.id === sessionId) return { ...session, replications: newReplications };
        return session;
      });
      return { ...b, sessions: newSessions };
    }
    return b;
  });
}

export function changeReplicationsInExperimentalDesigns(
  eds,
  experimentalDesignId,
  blockId,
  sessionId,
  newReplications,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: changeReplicationsInBlocks(
          ed.blocks,
          blockId,
          sessionId,
          newReplications,
        ),
      };
    }
    return ed;
  });
}
