export function removeIvFromIvs(ivs, independentVariableId) {
  return ivs.filter((iv) => {
    if (iv.id !== independentVariableId) return iv;
    return null;
  });
}

export function removeIvFromBlocks(blocks, blockId, independentVariableId) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      return {
        ...b,
        independentVariables: removeIvFromIvs(
          b.independentVariables,
          independentVariableId,
        ),
      };
    }
    return b;
  });
}

export function removeIvFromEds(
  eds,
  experimentalDesignId,
  blockId,
  independentVariableId,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: removeIvFromBlocks(ed.blocks, blockId, independentVariableId),
      };
    }
    return ed;
  });
}
