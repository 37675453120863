import { all, fork } from 'redux-saga/effects';
import experimentalDesignSaga from './experimentalDesign/ExperimentalDesignSaga';
import blockSaga from './block/BlockSaga';
import independentVariableSaga from './independentVariable/IndependentVariableSaga';
import levelSaga from './level/LevelSaga';
import SessionSaga from './session/SessionSaga';

export default function* experimentalSaga() {
  yield all([
    fork(experimentalDesignSaga),
    fork(blockSaga),
    fork(independentVariableSaga),
    fork(levelSaga),
    fork(SessionSaga),
  ]);
}
