export function sortLevelFromLevels(levels, levelId, newIndex) {
  let tempLevel = {};
  const newLevels = levels.filter((l) => {
    if (l.id !== levelId) return l;
    tempLevel = l;
    return null;
  });
  newLevels.splice(newIndex, 0, tempLevel);
  return newLevels;
}

export function sortLevelFromIvs(
  independentVariables,
  independentVariableId,
  levelId,
  newIndex,
) {
  return independentVariables.map((iv) => {
    if (iv.id === independentVariableId) {
      return { ...iv, levels: sortLevelFromLevels(iv.levels, levelId, newIndex) };
    }
    return iv;
  });
}

export function sortLevelFromBlocks(
  blocks,
  blockId,
  independentVariableId,
  levelId,
  newIndex,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      return {
        ...b,
        independentVariables: sortLevelFromIvs(
          b.independentVariables,
          independentVariableId,
          levelId,
          newIndex,
        ),
      };
    }
    return b;
  });
}

export function sortLevelFromEds(
  eds,
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
  newIndex,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: sortLevelFromBlocks(
          ed.blocks,
          blockId,
          independentVariableId,
          levelId,
          newIndex,
        ),
      };
    }
    return ed;
  });
}
