import React, { Component } from 'react';
import ExperimentalDesign from './ExperimentalDesign';
import Block from './Block';
import IndependentVariable from './IndependentVariable';
import Level from './Level';
import ItemTypes from './ItemTypes';

class ContainerNew extends Component {
  static NewEd() {
    const ed = {
      title: 'NEW ED',
      new: true,
      id: 'ed_new',
      top: 0,
      left: 260,
      blocks: [],
      sessions: [],
      colour: '#0000ff',
    };
    const newItem = true;
    return (
      <ExperimentalDesign
        key={ed.id}
        id={ed.id}
        ed={ed}
        itemType={ItemTypes.EXPERIMENTAL_DESIGN}
        newItem={newItem}
      />
    );
  }

  static NewBlock() {
    const block = {
      id: 'block_new',
      new: true,
      top: 0,
      left: 620,
      independentVariables: [],
      sessions: [
        {
          id: 'session_ui21z432b',
          counterBalancingMethod: 'LATIN_SQUARE',
          replications: 1,
          serial: true,
        },
      ],
    };
    const sessions = [];
    return (
      <Block
        key={block.id}
        id={block.id}
        block={block}
        edSessions={sessions}
        independentVariables={block.independentVariables}
        itemType={ItemTypes.BLOCK}
        blockClassName="block-in-container"
      />
    );
  }

  static NewIv() {
    const iv = {
      id: 'iv_new',
      title: 'NEW IV',
      new: true,
      top: 20,
      left: 10,
      levels: [],
    };
    const edSessions = [];
    return (
      <IndependentVariable
        key={iv.id}
        id={iv.id}
        independentVariable={iv}
        edSessions={edSessions}
        levels={iv.levels}
        itemType={ItemTypes.INDEPENDENT_VARIABLE}
        independentVariableClassName="independent-variable-in-container"
      />
    );
  }

  static NewLevel() {
    const level = {
      id: 'level_new',
      title: 'NEW LEVEL',
      new: true,
      top: 70,
      left: 260,
    };
    const edSessions = [];

    return (
      <Level
        key={level.id}
        id={level.id}
        level={level}
        edSessions={edSessions}
        itemType={ItemTypes.LEVEL}
        levelClassName="level-in-container"
      />
    );
  }

  render() {
    return (
      <div>
        {ContainerNew.NewEd()}
        {ContainerNew.NewBlock()}
        {ContainerNew.NewIv()}
        {ContainerNew.NewLevel()}
      </div>
    );
  }
}

export default ContainerNew;
