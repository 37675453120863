import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import {
  BLOCK_MOVE_SUCCESS,
  BLOCK_SORT_SUCCESS,
  BLOCK_ADD_SUCCESS,
  BLOCK_REMOVE_SUCCESS,
  BLOCK_CHANGE_CB_SUCCESS,
  BLOCK_CHANGE_REPLICATIONS_SUCCESS,
  BLOCK_CHANGE_SERIAL_SUCCESS,
} from './block/BlockAction';
import {
  EXPERIMENTAL_DESIGN_MOVE_SUCCESS,
  ED_ADD_NEW_ED_SUCCESS,
  ED_REMOVE_ED_SUCCESS,
  ED_RENAME_ED_SUCCESS,
  ED_SELECT_ED_SUCCESS,
  ED_CHANGE_COLOUR_SUCCESS,
} from './experimentalDesign/ExperimentalDesignAction';
import {
  IV_ADD_SUCCESS,
  IV_REMOVE_SUCCESS,
  IV_MOVE_SUCCESS,
  IV_SORT_SUCCESS,
  IV_RENAME_IV_SUCCESS,
} from './independentVariable/IndependentVariableAction';
import {
  LEVEL_ADD_NEW_LEVEL_SUCCESS,
  LEVEL_MOVE_LEVEL_SUCCESS,
  LEVEL_DELETE_LEVEL_SUCCESS,
  LEVEL_SORT_LEVEL_SUCCESS,
  LEVEL_RENAME_LEVEL_SUCCESS,
} from './level/LevelAction';
import {
  SESSION_ADD_SESSION_SUCCESS,
  SESSION_DELETE_SESSION_SUCCESS,
  SESSION_RENAME_SESSION_SUCCESS,
  SESSION_TOGGLE_UNEQUAL_LEVEL_SUCCESS,
  SESSION_SET_REPITITIONS_SUCCESS,
  UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_SUCCESS,
  UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_SUCCESS,
} from './session/SessionAction';
import { mockData } from '../../data/mockData';

/* const initialState = {
  experimentalDesigns: mockData.experimentalDesigns,
  blocks: mockData.blocks,
  independentVariables: mockData.independentVariables,
  levels: mockData.levels,
  selectedExperimentalDesignId: mockData.selectedExperimentalDesignId,
}; */

const initialState = {
  experimentalDesigns: mockData.experimentalDesigns,
  blocks: [],
  independentVariables: [],
  levels: [],
  selectedExperimentalDesignId: mockData.selectedExperimentalDesignId,
};

export default handleActions(
  {
    // Blocks
    [BLOCK_MOVE_SUCCESS]: (state, action) => immutable(state, {
      blocks: { $set: action.blocks },
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [BLOCK_SORT_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [BLOCK_ADD_SUCCESS]: (state, action) => immutable(state, {
      blocks: { $set: action.blocks },
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [BLOCK_REMOVE_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
    }),
    [BLOCK_CHANGE_CB_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
    }),
    [BLOCK_CHANGE_REPLICATIONS_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
    }),
    [BLOCK_CHANGE_SERIAL_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
    }),

    // Experimental Designs
    [EXPERIMENTAL_DESIGN_MOVE_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      selectedExperimentalDesignId: { $set: action.selectedExperimentalDesignId },
    }),
    [ED_ADD_NEW_ED_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      selectedExperimentalDesignId: { $set: action.selectedExperimentalDesignId },
    }),
    [ED_REMOVE_ED_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      selectedExperimentalDesignId: { $set: action.selectedExperimentalDesignId },
    }),
    [ED_RENAME_ED_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [ED_SELECT_ED_SUCCESS]: (state, action) => immutable(state, {
      selectedExperimentalDesignId: { $set: action.selectedExperimentalDesignId },
    }),
    [ED_CHANGE_COLOUR_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),

    // IVs
    [IV_ADD_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
      independentVariables: { $set: action.independentVariables },
    }),
    [IV_REMOVE_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
      independentVariables: { $set: action.independentVariables },
    }),
    [IV_MOVE_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
      independentVariables: { $set: action.independentVariables },
    }),
    [IV_SORT_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
    }),
    [IV_RENAME_IV_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
      blocks: { $set: action.blocks },
      independentVariables: { $set: action.independentVariables },
    }),

    // LEVELS
    [LEVEL_ADD_NEW_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      levels: { $set: action.levels },
      independentVariables: { $set: action.independentVariables },
      blocks: { $set: action.blocks },
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [LEVEL_MOVE_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      levels: { $set: action.levels },
      independentVariables: { $set: action.independentVariables },
      blocks: { $set: action.blocks },
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [LEVEL_DELETE_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      levels: { $set: action.levels },
      independentVariables: { $set: action.independentVariables },
      blocks: { $set: action.blocks },
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [LEVEL_SORT_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      independentVariables: { $set: action.independentVariables },
      blocks: { $set: action.blocks },
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [LEVEL_RENAME_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      levels: { $set: action.levels },
      independentVariables: { $set: action.independentVariables },
      blocks: { $set: action.blocks },
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),

    // Sessions
    [SESSION_ADD_SESSION_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [SESSION_DELETE_SESSION_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [SESSION_RENAME_SESSION_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [SESSION_TOGGLE_UNEQUAL_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [SESSION_SET_REPITITIONS_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
    [UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_SUCCESS]: (state, action) => immutable(state, {
      experimentalDesigns: { $set: action.experimentalDesigns },
    }),
  },
  initialState,
);
