export function renameLevelInLevels(levels, levelId, newName) {
  return levels.map((l) => {
    if (l.id === levelId) {
      return { ...l, title: newName };
    }
    return l;
  });
}

export function renameLevelInIndependentVariables(
  independentVariables,
  independentVariableId,
  levelId,
  newName,
) {
  return independentVariables.map((iv) => {
    if (iv.id === independentVariableId) {
      return { ...iv, levels: renameLevelInLevels(iv.levels, levelId, newName) };
    }
    return iv;
  });
}

export function renameLevelInBlocks(
  blocks,
  blockId,
  independentVariableId,
  levelId,
  newName,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      return {
        ...b,
        independentVariables: renameLevelInIndependentVariables(
          b.independentVariables,
          independentVariableId,
          levelId,
          newName,
        ),
      };
    }
    return b;
  });
}

export function renameLevelInEds(
  eds,
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
  newName,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: renameLevelInBlocks(
          ed.blocks,
          blockId,
          independentVariableId,
          levelId,
          newName,
        ),
      };
    }
    return ed;
  });
}
