import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from '@material-ui/core';
import { toggleSessionUnequalLevelAction } from '../../store/experimentalCanvas/session/SessionAction';


const LevelSession = ({ dispatch, unequalLevels }) => {
  const toggleValue = (e) => {
    const { checked, value } = e.target;
    const id = value;
    dispatch(toggleSessionUnequalLevelAction(
      id,
      checked,
    ));
  };

  const unequalLevelsRender = () => unequalLevels.map(uel => (
    <div key={uel.id} className="level-session__unequal-level">
      <Tooltip title="Toggle value">
        <Checkbox
          checked={uel.sessionUnequalLevelValue}
          onChange={toggleValue}
          value={uel.id}
        />
      </Tooltip>
    </div>
  ));

  return (
    <div className="level-session">
      {unequalLevelsRender(unequalLevels)}
    </div>
  );
};

LevelSession.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  unequalLevels: PropTypes.any.isRequired,
};

export default LevelSession;
