export function sortIvFromIvs(ivs, independentVariableId, newIndex) {
  let tempIv = {};
  const newIvs = ivs.filter((iv) => {
    if (iv.id !== independentVariableId) return iv;
    tempIv = iv;
    return null;
  });
  newIvs.splice(newIndex, 0, tempIv);
  return newIvs;
}

export function sortIvFromBlocks(
  blocks,
  blockId,
  independentVariableId,
  newIndex,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      return {
        ...b,
        independentVariables: sortIvFromIvs(
          b.independentVariables,
          independentVariableId,
          newIndex,
        ),
      };
    }
    return b;
  });
}

export function sortIvFromEds(
  eds,
  experimentalDesignId,
  blockId,
  independentVariableId,
  newIndex,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: sortIvFromBlocks(
          ed.blocks,
          blockId,
          independentVariableId,
          newIndex,
        ),
      };
    }
    return ed;
  });
}
