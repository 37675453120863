import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ItemTypes from './ItemTypes';
import { removeIndependentVariableAction } from '../../store/experimentalCanvas/independentVariable/IndependentVariableAction';
import { removeExperimentalDesignAction } from '../../store/experimentalCanvas/experimentalDesign/ExperimentalDesignAction';
import { removeBlockAction } from '../../store/experimentalCanvas/block/BlockAction';
import { deleteLevelAction } from '../../store/experimentalCanvas/level/LevelAction';

class ContainerBin extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
  };

  removeExperimentalDesign(experimentalDesignId) {
    const { dispatch } = this.props;
    dispatch(removeExperimentalDesignAction(experimentalDesignId));
  }

  removeBlock(experimentalDesignId, blockId) {
    const { dispatch } = this.props;
    dispatch(removeBlockAction(experimentalDesignId, blockId));
  }

  removeIndependentVariable(
    experimentalDesignId,
    blockId,
    independentVariableId,
  ) {
    const { dispatch } = this.props;
    dispatch(
      removeIndependentVariableAction(
        experimentalDesignId,
        blockId,
        independentVariableId,
      ),
    );
  }

  removeLevel(experimentalDesignId, blockId, independentVariableId, levelId) {
    const { dispatch } = this.props;
    dispatch(
      deleteLevelAction(
        experimentalDesignId,
        blockId,
        independentVariableId,
        levelId,
      ),
    );
  }

  render() {
    const { connectDropTarget } = this.props;

    return connectDropTarget(<div className="container-bin">TRASH ZONE</div>);
  }
}

const binTarget = {
  drop(props, monitor, component) {
    if (!component) {
      return;
    }
    const item = monitor.getItem();
    // const { ed, block, iv, level } = monitor.getItem();
    const {
      ed,
      block,
      blockId,
      experimentalDesignId,
      independentVariable,
      independentVariableId,
      id,
    } = monitor.getItem();
    const { itemType } = item;

    if (itemType === ItemTypes.EXPERIMENTAL_DESIGN) {
      component.removeExperimentalDesign(ed.id);
    }
    // check wether the block comes from container or experimental design
    if (itemType === ItemTypes.BLOCK) {
      component.removeBlock(experimentalDesignId, block.id);
    }

    // check wether the iv comes from container, experimental design, or block
    if (itemType === ItemTypes.INDEPENDENT_VARIABLE) {
      component.removeIndependentVariable(
        experimentalDesignId,
        blockId,
        independentVariable.id,
      );
    }

    if (itemType === ItemTypes.LEVEL) {
      component.removeLevel(
        experimentalDesignId,
        blockId,
        independentVariableId,
        id,
      );
    }
  },
  canDrop(props, monitor) {
    const {
      ed, block, iv, level,
    } = monitor.getItem();

    if (ed !== undefined && ed.new) return false;
    if (block !== undefined && block.new) return false;
    if (iv !== undefined && iv.new) return false;
    if (level !== undefined && level.new) return false;
    return true;
  },
};

// eslint-disable-next-line no-shadow
function collectObjects(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function mapStateToProps(state) {
  return {
    zoomFactor: state.zoom.zoomFactor,
  };
}

export default connect(mapStateToProps)(
  flow(
    DropTarget(
      [
        ItemTypes.EXPERIMENTAL_DESIGN,
        ItemTypes.BLOCK,
        ItemTypes.INDEPENDENT_VARIABLE,
        ItemTypes.LEVEL,
      ],
      binTarget,
      collectObjects,
    ),
  )(ContainerBin),
);
