import { takeEvery, put, select } from 'redux-saga/effects';
import {
  SESSION_ADD_SESSION_START,
  SESSION_ADD_SESSION_SUCCESS,
  SESSION_DELETE_SESSION_START,
  SESSION_DELETE_SESSION_SUCCESS,
  SESSION_RENAME_SESSION_START,
  SESSION_RENAME_SESSION_SUCCESS,
  SESSION_TOGGLE_UNEQUAL_LEVEL_START,
  SESSION_TOGGLE_UNEQUAL_LEVEL_SUCCESS,
  SESSION_SET_REPITITIONS_START,
  SESSION_SET_REPITITIONS_SUCCESS,
  UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_START,
  UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_SUCCESS,
  UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_START,
  UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_SUCCESS,
} from './SessionAction';
import { getAllExperimentalDesigns } from '../selector';
import {
  addSessionToExperimentalDesign,
  removeSessionFromExperimentalDesign,
} from './SessionHelperUpdateBlocks';
import {
  toggleUnequalLevelInLevel,
  addUnequalLevelToSession,
  deleteUnequalLevelFromSession,
} from './SessionHelperUpdateUnequalLevel';

function* addSession(action) {
  const { experimentalDesignId } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = addSessionToExperimentalDesign(eds, experimentalDesignId);

  yield put({
    type: SESSION_ADD_SESSION_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* deleteSession(action) {
  const { experimentalDesignId, sessionId } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = removeSessionFromExperimentalDesign(eds, experimentalDesignId, sessionId);

  yield put({
    type: SESSION_DELETE_SESSION_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* renameSession(action) {
  const { experimentalDesignId, sessionId, newName } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      const newSessions = ed.sessions.map((session) => {
        if (session.id !== sessionId) return session;
        return { ...session, title: newName };
      });

      return { ...ed, sessions: newSessions };
    }
    return ed;
  });

  yield put({
    type: SESSION_RENAME_SESSION_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* changeRepititions(action) {
  const { sessionId, newRepititions } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = eds.map((ed) => {
    const newSessions = ed.sessions.map((session) => {
      if (session.id === sessionId) return { ...session, repititions: newRepititions };
      return session;
    });
    return { ...ed, sessions: newSessions };
  });

  yield put({
    type: SESSION_SET_REPITITIONS_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* toggleRepition(action) {
  const {
    id,
    checked,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = toggleUnequalLevelInLevel(eds, id, checked);

  yield put({
    type: SESSION_TOGGLE_UNEQUAL_LEVEL_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* addUnequalLevel(action) {
  const { experimentalDesignId, sessionId } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = addUnequalLevelToSession(eds, experimentalDesignId, sessionId);

  yield put({
    type: UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* deleteUnequalLevel(action) {
  const { unequalLevelId } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = deleteUnequalLevelFromSession(eds, unequalLevelId);

  yield put({
    type: UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_SUCCESS,
    experimentalDesigns: newEds,
  });
}

const sessionSaga = function* () {
  yield takeEvery(SESSION_ADD_SESSION_START, addSession);
  yield takeEvery(SESSION_DELETE_SESSION_START, deleteSession);
  yield takeEvery(SESSION_RENAME_SESSION_START, renameSession);
  yield takeEvery(SESSION_TOGGLE_UNEQUAL_LEVEL_START, toggleRepition);
  yield takeEvery(SESSION_SET_REPITITIONS_START, changeRepititions);
  yield takeEvery(UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_START, addUnequalLevel);
  yield takeEvery(UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_START, deleteUnequalLevel);
};

export default sessionSaga;
