export function removeLevelFromLevels(levels, levelId) {
  return levels.filter((l) => {
    if (l.id !== levelId) return l;
    return undefined;
  });
}

export function removeLevelFromIvs(ivs, independentVariableId, levelId) {
  return ivs.map((iv) => {
    if (iv.id === independentVariableId) {
      return {
        ...iv,
        levels: removeLevelFromLevels(iv.levels, levelId),
      };
    }
    return iv;
  });
}

export function removeLevelFromBlocks(
  blocks,
  blockId,
  independentVariableId,
  levelId,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      return {
        ...b,
        independentVariables: removeLevelFromIvs(
          b.independentVariables,
          independentVariableId,
          levelId,
        ),
      };
    }
    return b;
  });
}

export function removeLevelFromEds(
  experimentalDesigns,
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
) {
  return experimentalDesigns.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: removeLevelFromBlocks(
          ed.blocks,
          blockId,
          independentVariableId,
          levelId,
        ),
      };
    }
    return ed;
  });
}
