export const BLOCK_MOVE_START = 'BLOCK_MOVE_START';
export const BLOCK_MOVE_SUCCESS = 'BLOCK_MOVE_SUCCESS';

export const BLOCK_SORT_START = 'BLOCK_SORT_START';
export const BLOCK_SORT_SUCCESS = 'BLOCK_SORT_SUCCESS';

export const BLOCK_REMOVE_START = 'BLOCK_REMOVE_START';
export const BLOCK_REMOVE_SUCCESS = 'BLOCK_REMOVE_SUCCESS';

export const BLOCK_ADD_START = 'BLOCK_ADD_START';
export const BLOCK_ADD_SUCCESS = 'BLOCK_ADD_SUCCESS';

export const BLOCK_CHANGE_CB_START = 'BLOCK_CHANGE_CB_START';
export const BLOCK_CHANGE_CB_SUCCESS = 'BLOCK_CHANGE_CB_SUCCESS';

export const BLOCK_CHANGE_REPLICATIONS_START = 'BLOCK_CHANGE_REPLICATIONS_START';
export const BLOCK_CHANGE_REPLICATIONS_SUCCESS = 'BLOCK_CHANGE_REPLICATIONS_SUCCESS';

export const BLOCK_CHANGE_SERIAL_START = 'BLOCK_CHANGE_SERIAL_START';
export const BLOCK_CHANGE_SERIAL_SUCCESS = 'BLOCK_CHANGE_SERIAL_SUCCESS';

export const moveBlockAction = (
  targetExperimentalDesignId,
  sourceExperimentalDesignId,
  block,
) => ({
  type: BLOCK_MOVE_START,
  targetExperimentalDesignId,
  sourceExperimentalDesignId,
  block,
});

export const sortBlockAction = (experimentalDesignId, blockId, newIndex) => ({
  type: BLOCK_SORT_START,
  experimentalDesignId,
  blockId,
  newIndex,
});

export const addNewBlockAction = (experimentalDesignId, left, top) => ({
  type: BLOCK_ADD_START,
  experimentalDesignId,
  left,
  top,
});

export const removeBlockAction = (experimentalDesignId, blockId) => ({
  type: BLOCK_REMOVE_START,
  experimentalDesignId,
  blockId,
});

export const changeCounterBalancingOfBlockAction = (
  experimentalDesignId,
  blockId,
  sessionId,
  counterBalancingMethod,
) => ({
  type: BLOCK_CHANGE_CB_START,
  experimentalDesignId,
  blockId,
  sessionId,
  counterBalancingMethod,
});

export const changeReplicationsAction = (
  experimentalDesignId,
  blockId,
  sessionId,
  newReplications,
) => ({
  type: BLOCK_CHANGE_REPLICATIONS_START,
  experimentalDesignId,
  blockId,
  sessionId,
  newReplications,
});

export const changeSerialOfBlockAction = (
  experimentalDesignId,
  blockId,
  sessionId,
  newSerial,
) => ({
  type: BLOCK_CHANGE_SERIAL_START,
  experimentalDesignId,
  blockId,
  sessionId,
  newSerial,
});
