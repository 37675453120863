import { constructSessions } from '../level/LevelHelperAdd';

function updateUnequalLevels(edSessions, newIv) {
  const tempLevels = newIv.levels.map(level => ({ ...level, sessions: constructSessions(edSessions) }));
  return { ...newIv, levels: tempLevels };
}

export function addIvToIvs(ivs, newIv) {
  const newIvs = ivs.map(iv => iv);
  if (newIv.left !== undefined && newIv.top !== undefined) {
    newIvs.push(newIv);
  }
  return newIvs;
}

export function addIvToBlocks(blocks, blockId, newIv) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      const tempIvs = b.independentVariables;
      tempIvs.push(newIv);
      return { ...b, independentVariables: tempIvs };
    }
    return b;
  });
}

export function addIvToEds(eds, experimentalDesignId, blockId, newIv) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      const updatedIv = updateUnequalLevels(ed.sessions, newIv);
      return { ...ed, blocks: addIvToBlocks(ed.blocks, blockId, updatedIv) };
    }
    return ed;
  });
}
