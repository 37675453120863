import { takeEvery, put, select } from 'redux-saga/effects';
import {
  BLOCK_ADD_START,
  BLOCK_ADD_SUCCESS,
  BLOCK_MOVE_START,
  BLOCK_MOVE_SUCCESS,
  BLOCK_REMOVE_START,
  BLOCK_REMOVE_SUCCESS,
  BLOCK_SORT_START,
  BLOCK_SORT_SUCCESS,
  BLOCK_CHANGE_CB_START,
  BLOCK_CHANGE_CB_SUCCESS,
  BLOCK_CHANGE_REPLICATIONS_START,
  BLOCK_CHANGE_REPLICATIONS_SUCCESS,
  BLOCK_CHANGE_SERIAL_START,
  BLOCK_CHANGE_SERIAL_SUCCESS,
} from './BlockAction';
import {
  removeBlockFromBlocks,
  removeBlockFromExperimentalDesigns,
} from './BlockHelperRemove';
import { addNewBlockToBlocks, addNewBlockToEds } from './BlockHelperAdd';
import {
  changeCounterBalancingMethodInBlocks,
  changeCounterBalancingMethodInExperimentalDesigns,
} from './blockHelperCounterBalancing';
import {
  changeReplicationsInBlocks,
  changeReplicationsInExperimentalDesigns,
} from './blockHelperReplications';
import {
  changeSerialInBlocks,
  changeSerialInExperimentalDesigns,
} from './BlockHelperSerial';
import { getAllExperimentalDesigns, getAllBlocks } from '../selector';

const uuidv4 = require('uuid/v4');

function* moveBlock(action) {
  const {
    targetExperimentalDesignId,
    sourceExperimentalDesignId,
    block,
  } = action;
  const blocks = yield select(getAllBlocks);
  const eds = yield select(getAllExperimentalDesigns);

  const tempEds = removeBlockFromExperimentalDesigns(
    eds,
    sourceExperimentalDesignId,
    block.id,
  );
  const newEds = addNewBlockToEds(tempEds, targetExperimentalDesignId, block);

  const tempBlocks = removeBlockFromBlocks(blocks, block.id);
  const newBlocks = addNewBlockToBlocks(tempBlocks, block);

  yield put({
    type: BLOCK_MOVE_SUCCESS,
    experimentalDesigns: newEds,
    blocks: newBlocks,
  });
}

function* sortBlock(action) {
  const { experimentalDesignId, blockId, newIndex } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blockToMove = eds
    .filter(ed => ed.id === experimentalDesignId)[0]
    .blocks.filter(b => b.id === blockId)[0];

  const tempEds = removeBlockFromExperimentalDesigns(
    eds,
    experimentalDesignId,
    blockId,
  );

  const newEds = tempEds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      // TODO: put into place: indexExperimentalDesignTarget
      const newBlocks = ed.blocks;
      newBlocks.splice(newIndex, 0, blockToMove);
      return { ...ed, blocks: newBlocks };
    }
    return ed;
  });

  yield put({
    type: BLOCK_SORT_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* addNewBlock(action) {
  const { experimentalDesignId, left, top } = action;
  const blocks = yield select(getAllBlocks);
  const eds = yield select(getAllExperimentalDesigns);
  let blockId = 'block_';
  blockId += uuidv4();
  const newBlock = {
    id: blockId,
    title: 'NEW BLOCK',
    counterBalancingMethod: 'LATIN_SQUARE',
    replications: 1,
    serial: true,
    top,
    left,
    independentVariables: [],
    sessions: [],
  };

  yield put({
    type: BLOCK_ADD_SUCCESS,
    experimentalDesigns: addNewBlockToEds(eds, experimentalDesignId, newBlock),
    blocks: addNewBlockToBlocks(blocks, newBlock),
  });
}

function* removeBlock(action) {
  const { experimentalDesignId, blockId } = action;
  const blocks = yield select(getAllBlocks);
  const eds = yield select(getAllExperimentalDesigns);

  yield put({
    type: BLOCK_REMOVE_SUCCESS,
    blocks: removeBlockFromBlocks(blocks, blockId),
    experimentalDesigns: removeBlockFromExperimentalDesigns(
      eds,
      experimentalDesignId,
      blockId,
    ),
  });
}

function* changeCounterBalancingMethod(action) {
  const {
    experimentalDesignId,
    blockId,
    sessionId,
    counterBalancingMethod,
  } = action;
  const blocks = yield select(getAllBlocks);
  const eds = yield select(getAllExperimentalDesigns);

  yield put({
    type: BLOCK_CHANGE_CB_SUCCESS,
    blocks: changeCounterBalancingMethodInBlocks(
      blocks,
      blockId,
      sessionId,
      counterBalancingMethod,
    ),
    experimentalDesigns: changeCounterBalancingMethodInExperimentalDesigns(
      eds,
      experimentalDesignId,
      blockId,
      sessionId,
      counterBalancingMethod,
    ),
  });
}

function* changeReplications(action) {
  const {
    experimentalDesignId, blockId, sessionId, newReplications,
  } = action;
  const blocks = yield select(getAllBlocks);
  const eds = yield select(getAllExperimentalDesigns);

  yield put({
    type: BLOCK_CHANGE_REPLICATIONS_SUCCESS,
    blocks: changeReplicationsInBlocks(
      blocks,
      blockId,
      sessionId,
      newReplications,
    ),
    experimentalDesigns: changeReplicationsInExperimentalDesigns(
      eds,
      experimentalDesignId,
      blockId,
      sessionId,
      newReplications,
    ),
  });
}

function* changeSerialOfBlock(action) {
  const {
    experimentalDesignId,
    blockId,
    sessionId,
    newSerial,
  } = action;
  const blocks = yield select(getAllBlocks);
  const eds = yield select(getAllExperimentalDesigns);

  yield put({
    type: BLOCK_CHANGE_SERIAL_SUCCESS,
    blocks: changeSerialInBlocks(
      blocks,
      blockId,
      sessionId,
      newSerial,
    ),
    experimentalDesigns: changeSerialInExperimentalDesigns(
      eds,
      experimentalDesignId,
      blockId,
      sessionId,
      newSerial,
    ),
  });
}

const blockSaga = function* () {
  yield takeEvery(BLOCK_MOVE_START, moveBlock);
  yield takeEvery(BLOCK_SORT_START, sortBlock);
  yield takeEvery(BLOCK_ADD_START, addNewBlock);
  yield takeEvery(BLOCK_REMOVE_START, removeBlock);
  yield takeEvery(BLOCK_CHANGE_CB_START, changeCounterBalancingMethod);
  yield takeEvery(BLOCK_CHANGE_REPLICATIONS_START, changeReplications);
  yield takeEvery(BLOCK_CHANGE_SERIAL_START, changeSerialOfBlock);
};

export default blockSaga;
