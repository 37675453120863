import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { independentVariableProps } from './PropTypes';
import ItemTypes from './ItemTypes';
import Level from './Level';
import {
  sortIndependentVariableAction,
  renameIndependentVariable,
} from '../../store/experimentalCanvas/independentVariable/IndependentVariableAction';
import {
  addNewLevelAction,
  moveLevelAction,
} from '../../store/experimentalCanvas/level/LevelAction';

class IndependentVariable extends Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      connectDropTarget: PropTypes.func.isRequired,
      connectDragSource: PropTypes.func.isRequired,
      isDragging: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      independentVariable: PropTypes.shape(independentVariableProps).isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      edSessions: PropTypes.array.isRequired,
      independentVariableClassName: PropTypes.string.isRequired,
      experimentalDesignId: PropTypes.string,
      blockId: PropTypes.string,
    };

    static defaultProps = {
      experimentalDesignId: undefined,
      blockId: undefined,
    }

    constructor(props) {
      super(props);
      this.state = {
        showEdit: false,
      };
    }

    componentWillMount() {
      document.addEventListener('mousedown', this.endChangeName, false);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.endChangeName, false);
    }

    changeName = (e) => {
      const newName = e.target.value;
      const {
        dispatch, experimentalDesignId, blockId, id,
      } = this.props;
      dispatch(renameIndependentVariable(experimentalDesignId, blockId, id, newName));
    }

    endChangeName = (e) => {
      const { id } = this.props;
      const inputId = `input_${id}`;
      if (e.toElement !== undefined && e.toElement.id !== inputId) {
        this.setState({
          showEdit: false,
        });
      }
      if (e.key === 'Enter') this.setState({ showEdit: false });
    }

    startChangeName() {
      this.setState({ showEdit: true });
    }

    LevelsRender(experimentalDesignId, blockId, independentVariableId, levels) {
      const { connectDropTarget, edSessions } = this.props;

      const levelsRender = levels.map((level, i) => (
        <Level
          key={level.id}
          id={level.id}
          levelId={level.id}
          level={level}
          edSessions={edSessions}
          index={i}
          experimentalDesignId={experimentalDesignId}
          blockId={blockId}
          independentVariableId={independentVariableId}
          itemType={ItemTypes.LEVEL}
          levelClassName="level-in-independent-variable"
        />
      ));

      return connectDropTarget(
        <div>
          You can drag the level here
          {levelsRender}
        </div>,
      );
    }

    sortIndependenVariable(experimentalDesignId, blockId, independentVariableId, newIndex) {
      const { dispatch } = this.props;
      dispatch(sortIndependentVariableAction(
        experimentalDesignId,
        blockId,
        independentVariableId,
        newIndex,
      ));
    }

    addNewLevel() {
      const {
        dispatch, experimentalDesignId, blockId, id,
      } = this.props;
      dispatch(addNewLevelAction(experimentalDesignId, blockId, id, undefined, undefined));
    }

    moveLevel(sourceEdId, sourceblockId, sourceIvId, level) {
      const {
        dispatch, experimentalDesignId, blockId, id,
      } = this.props;
      dispatch(moveLevelAction(
        experimentalDesignId,
        blockId,
        id,
        sourceEdId,
        sourceblockId,
        sourceIvId,
        level,
      ));
    }

    render() {
      const {
        id,
        connectDragSource,
        isDragging,
        independentVariable,
        connectDropTarget,
        blockId,
        experimentalDesignId,
        independentVariableClassName,
      } = this.props;
      const opacity = isDragging ? 0 : 1;

      const { top, left } = independentVariable;
      const { showEdit } = this.state;

      return connectDropTarget(
        connectDragSource(
          <div style={{ opacity, left, top }} className={independentVariableClassName}>
            {showEdit
              ? (
                <TextField
                  required
                  id={`input_${independentVariable.id}`}
                  label="IV Name"
                  defaultValue={independentVariable.title}
                  onChange={this.changeName}
                  onKeyPress={this.endChangeName}
                  margin="normal"
                  variant="outlined"
                />
              )
              : (
                <div onDoubleClick={e => this.startChangeName(e)}>
                  {independentVariable.title}
                </div>
              )
                      }
            {this.LevelsRender(
              experimentalDesignId,
              blockId,
              id,
              independentVariable.levels,
            )}
          </div>,
        ),
      );
    }
}

const IndependentVariableSource = {
  beginDrag(props) {
    const {
      id, independentVariable, experimentalDesignId, blockId, index, itemType,
    } = props;
    return {
      id, independentVariable, experimentalDesignId, blockId, index, itemType,
    };
  },
};

const independentVariableTarget = {
  drop(props, monitor, component) {
    if (!component) return;
    const item = monitor.getItem();
    if (item.itemType !== ItemTypes.LEVEL) return;
    const {
      level, independentVariableId, blockId, experimentalDesignId,
    } = item;
    const currentIndependentVariableId = props.id;

    if (currentIndependentVariableId === independentVariableId) return;

    // add new level
    if (level.new) component.getDecoratedComponentInstance().addNewLevel();

    // move new level
    if (!level.new) {
      level.left = undefined;
      level.top = undefined;
      component.getDecoratedComponentInstance().moveLevel(
        experimentalDesignId,
        blockId,
        independentVariableId,
        level,
      );
    }
  },
  hover(props, monitor, component) {
    if (!component) return;
    const item = monitor.getItem();
    if (item.itemType !== ItemTypes.INDEPENDENT_VARIABLE) return;
    if (props.blockId !== item.blockId || item.blockId === undefined) return;

    const dragIndex = item.index; // the dragged item
    const hoverIndex = props.index; // the not-dragged item
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      findDOMNode(component).style.opacity = 0;
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    component.sortIndependenVariable(item.experimentalDesignId, item.blockId, item.id, hoverIndex);

    item.index = hoverIndex;
  },
};

// eslint-disable-next-line no-shadow
function collectIndependetVariable(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

// eslint-disable-next-line no-shadow
function collectObjects(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function mapStateToProps(state) {
  return {
    zoomFactor: state.zoom.zoomFactor,
  };
}

export default connect(mapStateToProps)(flow(
  DragSource(
    ItemTypes.INDEPENDENT_VARIABLE,
    IndependentVariableSource,
    collectIndependetVariable,
  ),
  DropTarget(
    [ItemTypes.INDEPENDENT_VARIABLE, ItemTypes.LEVEL],
    independentVariableTarget,
    collectObjects,
  ),
)(IndependentVariable));
