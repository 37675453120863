import { takeEvery, put, select } from 'redux-saga/effects';
import {
  EXPERIMENTAL_DESIGN_MOVE_START,
  EXPERIMENTAL_DESIGN_MOVE_SUCCESS,
  ED_ADD_NEW_ED_START,
  ED_ADD_NEW_ED_SUCCESS,
  ED_REMOVE_ED_START,
  ED_REMOVE_ED_SUCCESS,
  ED_RENAME_ED_START,
  ED_RENAME_ED_SUCCESS,
  ED_SELECT_ED_START,
  ED_SELECT_ED_SUCCESS,
  ED_CHANGE_COLOUR_START,
  ED_CHANGE_COLOUR_SUCCESS,
} from './ExperimentalDesignAction';
import { getAllExperimentalDesigns } from '../selector';
import transformExperimentalDesignToTsl from './TSLExport';

const uuidv4 = require('uuid/v4');

function* moveExperimentalDesign(action) {
  const { id, left, top } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = eds.map((ed) => {
    if (ed.id === id) {
      return { ...ed, left, top };
    }
    return ed;
  });

  yield put({
    type: EXPERIMENTAL_DESIGN_MOVE_SUCCESS,
    experimentalDesigns: newEds,
    selectedExperimentalDesignId: id,
  });
}

function* addNewEdToContainer(action) {
  const { left, top } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const newEds = eds.map(ed => ed);

  let edId = 'ed_';
  edId += uuidv4();
  const newEd = {
    id: edId,
    title: 'NEW ED',
    selected: true,
    top,
    left,
    blocks: [],
    sessions: [],
  };
  newEds.push(newEd);
  yield put({
    type: ED_ADD_NEW_ED_SUCCESS,
    experimentalDesigns: newEds,
    selectedExperimentalDesignId: edId,
  });
}

function* removeExperimentalDesign(action) {
  const { experimentalDesignId } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const newEds = eds.filter((ed) => {
    if (ed.id !== experimentalDesignId) return ed;
    return undefined;
  });

  yield put({
    type: ED_REMOVE_ED_SUCCESS,
    experimentalDesigns: newEds,
    selectedExperimentalDesignId: newEds[0].id,
  });
}

function* renameExperimentalDesign(action) {
  const { experimentalDesignId, newName } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return { ...ed, title: newName };
    }
    return ed;
  });


  yield put({
    type: ED_RENAME_ED_SUCCESS,
    experimentalDesigns: newEds,
  });
}

function* selectExperimentalDesign(action) {
  const { experimentalDesignId } = action;

  yield put({
    type: ED_SELECT_ED_SUCCESS,
    selectedExperimentalDesignId: experimentalDesignId,
  });
}

function* changeColourOfExperimentalDesign(action) {
  const { experimentalDesignId, newColour } = action;
  const eds = yield select(getAllExperimentalDesigns);

  const newEds = eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      console.log(transformExperimentalDesignToTsl(ed));
      return { ...ed, colour: newColour };
    }
    return ed;
  });

  yield put({
    type: ED_CHANGE_COLOUR_SUCCESS,
    experimentalDesigns: newEds,
  });
}

const experimentalDesignSaga = function* () {
  yield takeEvery(EXPERIMENTAL_DESIGN_MOVE_START, moveExperimentalDesign);
  yield takeEvery(ED_ADD_NEW_ED_START, addNewEdToContainer);
  yield takeEvery(ED_REMOVE_ED_START, removeExperimentalDesign);
  yield takeEvery(ED_RENAME_ED_START, renameExperimentalDesign);
  yield takeEvery(ED_SELECT_ED_START, selectExperimentalDesign);
  yield takeEvery(ED_CHANGE_COLOUR_START, changeColourOfExperimentalDesign);
};

export default experimentalDesignSaga;
