import { takeEvery, put } from 'redux-saga/effects';
import { ZOOM_UPDATE_ZOOM_START, ZOOM_UPDATE_ZOOM_SUCCESS } from './ZoomAction';

function* updateZoom(action) {
  const { zoomFactor } = action;

  yield put({
    type: ZOOM_UPDATE_ZOOM_SUCCESS,
    zoomFactor,
  });
}

const zoomSaga = function* () {
  yield takeEvery(ZOOM_UPDATE_ZOOM_START, updateZoom);
};

export default zoomSaga;
