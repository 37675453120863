import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  levelProps,
  independentVariableProps,
  blockProps,
  experimentalDesignProps,
} from './PropTypes';
import ExperimentalDesign from './ExperimentalDesign';
import Block from './Block';
import IndependentVariable from './IndependentVariable';
import Level from './Level';
import ContainerBin from './ContainerBin';
import ContainerNew from './ContainerNew';
import { updateZoom } from '../../store/zoomExperimentalDesign/ZoomAction';
import {
  moveExperimentalDesignAction,
  addNewEdToContainerAction,
} from '../../store/experimentalCanvas/experimentalDesign/ExperimentalDesignAction';
import {
  addNewIndependentVariableAction,
  moveIndependentVariableAction,
} from '../../store/experimentalCanvas/independentVariable/IndependentVariableAction';
import {
  moveBlockAction,
  addNewBlockAction,
} from '../../store/experimentalCanvas/block/BlockAction';
import {
  addNewLevelAction,
  moveLevelAction,
} from '../../store/experimentalCanvas/level/LevelAction';
import ItemTypes from './ItemTypes';

class Container extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    zoomFactor: PropTypes.number.isRequired,
    experimentalDesigns: PropTypes.arrayOf(PropTypes.shape(experimentalDesignProps)).isRequired,
    blocks: PropTypes.arrayOf(PropTypes.shape(blockProps)).isRequired,
    independentVariables: PropTypes.arrayOf(PropTypes.shape(independentVariableProps)).isRequired,
    levels: PropTypes.arrayOf(PropTypes.shape(levelProps)).isRequired,
  };

  static BlocksRender(blocks) {
    const sessions = [];
    const edBlocks = blocks.map(block => (
      <Block
        key={block.id}
        id={block.id}
        block={block}
        edSessions={sessions}
        sessions={sessions}
        itemType={ItemTypes.BLOCK}
        blockClassName="block-in-container"
      />
    ));
    return edBlocks;
  }

  static IvsRender(independentVariables) {
    const sessions = [];
    const edIvs = independentVariables.map(iv => (
      <IndependentVariable
        key={iv.id}
        id={iv.id}
        independentVariableId={iv.id}
        independentVariable={iv}
        edSessions={sessions}
        sessions={sessions}
        levels={iv.levels}
        itemType={ItemTypes.INDEPENDENT_VARIABLE}
        independentVariableClassName="independent-variable-in-container"
      />
    ));
    return edIvs;
  }

  static LevelsRender(levels) {
    const sessions = [];

    return levels.map(level => (
      <Level
        key={level.id}
        id={level.id}
        level={level}
        edSessions={sessions}
        itemType={ItemTypes.LEVEL}
        levelClassName="level-in-container"
      />
    ));
  }

  static EdsRender(experimentalDesigns) {
    const eds = experimentalDesigns.map(ed => (
      <ExperimentalDesign
        key={ed.id}
        id={ed.id}
        ed={ed}
        itemType={ItemTypes.EXPERIMENTAL_DESIGN}
      />
    ));
    return eds;
  }

  changeZoom = (e) => {
    const { dispatch } = this.props;
    dispatch(updateZoom(e.target.value));
  }

  addNewEdToContainer(left, top) {
    const { dispatch } = this.props;
    dispatch(addNewEdToContainerAction(left, top));
  }

  moveExperimentalDesign(id, left, top) {
    const { dispatch } = this.props;
    dispatch(moveExperimentalDesignAction(id, left, top));
  }

  addNewBlock(left, top) {
    const { dispatch } = this.props;
    dispatch(addNewBlockAction(undefined, left, top));
  }

  moveBlock(targetExperimentalDesignId, sourceExperimentalDesignId, block) {
    const { dispatch } = this.props;
    dispatch(
      moveBlockAction(
        targetExperimentalDesignId,
        sourceExperimentalDesignId,
        block,
      ),
    );
  }

  addNewIndependentVariable(left, top) {
    const { dispatch } = this.props;
    dispatch(addNewIndependentVariableAction(undefined, undefined, left, top));
  }

  moveIndependentVariable(
    targetEdId,
    targetBlockId,
    sourceEdId,
    sourceBlockId,
    independentVariable,
  ) {
    const { dispatch } = this.props;
    dispatch(
      moveIndependentVariableAction(
        targetEdId,
        targetBlockId,
        sourceEdId,
        sourceBlockId,
        independentVariable,
      ),
    );
  }

  addNewLevel(left, top) {
    const { dispatch } = this.props;
    dispatch(addNewLevelAction(undefined, undefined, undefined, left, top));
  }

  moveLevel(
    level,
    sourceExperimentalDesignId,
    sourceBlockId,
    sourceIndependentVariableId,
  ) {
    const { dispatch } = this.props;
    dispatch(
      moveLevelAction(
        undefined,
        undefined,
        undefined,
        sourceExperimentalDesignId,
        sourceBlockId,
        sourceIndependentVariableId,
        level,
      ),
    );
  }

  render() {
    const {
      connectDropTarget,
      zoomFactor,
      experimentalDesigns,
      blocks,
      independentVariables,
      levels,
    } = this.props;
    const height = 800; // TODO: Change when an element is dragged to the outside of the container
    const width = 2000;
    return connectDropTarget(
      <div className="container">
        <input
          type="range"
          min="0.2"
          max="2"
          step="0.2"
          value={zoomFactor}
          onChange={this.changeZoom}
        />
        {' '}
        Zoom Factor:
        {' '}
        {zoomFactor}
        <ContainerNew />
        <ContainerBin />
        <div style={{ width, height, zoom: zoomFactor }}>
          {Container.EdsRender(experimentalDesigns)}
          {Container.BlocksRender(blocks)}
          {Container.IvsRender(independentVariables)}
          {Container.LevelsRender(levels)}
        </div>
      </div>,
    );
  }
}

const containerTarget = {
  drop(props, monitor, component) {
    const element = monitor.getItem();
    const {
      block,
      ed,
      independentVariable,
      level,
      independentVariableId,
      blockId,
      experimentalDesignId,
    } = element;
    const delta = monitor.getDifferenceFromInitialOffset();
    if (!component || !element || !delta) {
      return;
    }

    // TODO: add yOffset of 20 px to calculation
    // TODO: recalculate size of container
    const invertedZoom = 1 / props.zoomFactor;
    let left = 0;
    let top = 0;

    // ALL ACTIONS OF EXPERIMENTAL DESIGN
    if (element.itemType === ItemTypes.EXPERIMENTAL_DESIGN) {
      left = Math.round(ed.left + delta.x * invertedZoom);
      top = Math.round(ed.top + delta.y * invertedZoom);

      // move new ED on container
      if (ed.new) component.addNewEdToContainer(left, top);

      // move ED within container
      if (!ed.new) component.moveExperimentalDesign(element.id, left, top);
      return;
    }

    // ALL ACTIONS OF BLOCK
    if (element.itemType === ItemTypes.BLOCK) {
      // move new block to container
      if (block.new) {
        left = Math.round(block.left + delta.x * invertedZoom);
        top = Math.round(block.top + delta.y * invertedZoom);
        component.addNewBlock(left, top);
        return;
      }

      if (element.experimentalDesignId !== undefined) {
        block.left = monitor.getClientOffset().x;
        block.top = monitor.getClientOffset().y;
      } else {
        block.left = Math.round(block.left + delta.x * invertedZoom);
        block.top = Math.round(block.top + delta.y * invertedZoom);
      }

      component.moveBlock(undefined, element.experimentalDesignId, block);
      return;
    }

    if (element.itemType === ItemTypes.INDEPENDENT_VARIABLE) {
      if (independentVariable.new) {
        left = Math.round(independentVariable.left + delta.x * invertedZoom);
        top = Math.round(independentVariable.top + delta.y * invertedZoom);
        component.addNewIndependentVariable(left, top);
        return;
      }

      if (!independentVariable.new) {
        if (blockId === undefined) {
          independentVariable.left = Math.round(
            independentVariable.left + delta.x * invertedZoom,
          );
          independentVariable.top = Math.round(
            independentVariable.top + delta.y * invertedZoom,
          );
        } else {
          independentVariable.left = monitor.getClientOffset().x * invertedZoom;
          independentVariable.top = monitor.getClientOffset().y * invertedZoom;
        }

        component.moveIndependentVariable(
          undefined,
          undefined,
          experimentalDesignId,
          blockId,
          independentVariable,
        );
      }
    }

    if (element.itemType === ItemTypes.LEVEL) {
      left = Math.round(level.left + delta.x * invertedZoom);
      top = Math.round(level.top + delta.y * invertedZoom);
      if (level.new) {
        component.addNewLevel(left, top);
        return;
      }
      if (!level.new) {
        if (independentVariableId === undefined) {
          level.left = Math.round(level.left + delta.x * invertedZoom);
          level.top = Math.round(level.top + delta.y * invertedZoom);
        } else {
          level.left = monitor.getClientOffset().x * invertedZoom;
          level.top = monitor.getClientOffset().y * invertedZoom;
        }

        component.moveLevel(
          level,
          experimentalDesignId,
          blockId,
          independentVariableId,
        );
      }
    }
  },
};

// eslint-disable-next-line no-shadow
function collectObjects(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function mapStateToProps(state) {
  return {
    zoomFactor: state.zoom.zoomFactor,
    experimentalDesigns: state.experimentalCanvas.experimentalDesigns,
    blocks: state.experimentalCanvas.blocks,
    independentVariables: state.experimentalCanvas.independentVariables,
    levels: state.experimentalCanvas.levels,
  };
}

export default connect(mapStateToProps)(
  flow(
    DropTarget(
      [
        ItemTypes.EXPERIMENTAL_DESIGN,
        ItemTypes.BLOCK,
        ItemTypes.INDEPENDENT_VARIABLE,
        ItemTypes.LEVEL,
      ],
      containerTarget,
      collectObjects,
    ),
  )(Container),
);
