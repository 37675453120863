import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { IconButton, Tooltip } from '@material-ui/core';
import { addUnequalLevelAction, deleteUnequalLevelAction } from '../../store/experimentalCanvas/session/SessionAction';

class UnequalLevelsSessionHead extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    unequalLevels: PropTypes.array.isRequired,
    experimentalDesignId: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
  };

  addUnequalLevel = () => {
    const { dispatch, experimentalDesignId, sessionId } = this.props;
    dispatch(addUnequalLevelAction(experimentalDesignId, sessionId));
    // this.forceUpdate();
  }

  deleteUnquealLevel(unequalLevelId) {
    const { dispatch } = this.props;
    dispatch(deleteUnequalLevelAction(unequalLevelId));
  }

  // eslint-disable-next-line class-methods-use-this
  unequalLevelsRender() {
    const { unequalLevels } = this.props;
    return unequalLevels.map((uel, index) => (
      <div key={uel} className="unequal-levels-session-head__unequal-level">
        {(index === 0)
          ? null
          : (
            <Tooltip title="Delete unequal level">
              <IconButton aria-label="Delete" onClick={() => this.deleteUnquealLevel(uel)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )
        }
      </div>
    ));
  }

  addUnequalLevelRender() {
    const { unequalLevels } = this.props;
    return (
      <>
        {(unequalLevels.length < 4) ? (
          <div className="unequal-levels-session-head__add">
            <Tooltip title="Add unequal level">
              <IconButton aria-label="Delete" onClick={this.addUnequalLevel}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <div className="unequal-levels-session-head">
        {this.unequalLevelsRender()}
        {this.addUnequalLevelRender()}
      </div>
    );
  }
}

export default UnequalLevelsSessionHead;
