import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Input,
  Typography,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { sessionProps } from './PropTypes';
import { deleteSessionAction, renameSessionAction, changeRepititionsAction } from '../../store/experimentalCanvas/session/SessionAction';
import UnequalLevelsSessionHead from './UnequalLevelsSessionHead';

class SessionHead extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    session: PropTypes.shape(sessionProps).isRequired,
    experimentalDesignId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showNameEdit: false,
    };
  }


  componentWillMount() {
    document.addEventListener('mousedown', this.endChangeName, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.endChangeName, false);
  }


  deleteSession = () => {
    const { id, experimentalDesignId, dispatch } = this.props;
    dispatch(deleteSessionAction(experimentalDesignId, id));
  }

  renameSession = (e) => {
    const newName = e.target.value;
    const { dispatch, experimentalDesignId, id } = this.props;
    if (newName.length > 0 && newName.length < 11) {
      dispatch(renameSessionAction(experimentalDesignId, id, newName));
    }
  }

  endChangeName = (e) => {
    const { id } = this.props;
    const inputId = `input_${id}`;
    if (e.toElement !== undefined && e.toElement.id !== inputId) {
      this.setState({ showNameEdit: false });
    }
    if (e.key === 'Enter') this.setState({ showNameEdit: false });
  };

  changeRepititionsHandler = (e) => {
    const {
      dispatch, id,
    } = this.props;
    dispatch(changeRepititionsAction(id, e.target.value));
  }

  startChangeName() {
    this.setState({ showNameEdit: true });
  }

  unequalLevelsRender() {
    const {
      dispatch, session, experimentalDesignId, id,
    } = this.props;
    const { unequalLevels } = session;

    return (
      <UnequalLevelsSessionHead
        experimentalDesignId={experimentalDesignId}
        dispatch={dispatch}
        sessionId={id}
        unequalLevels={unequalLevels}
      />
    );
  }

  renameTitleRender() {
    const { session } = this.props;
    return (
      <Input
        required
        id={`input_${session.id}`}
        label="Session Name"
        defaultValue={session.title}
        onChange={this.renameSession}
        onKeyPress={this.endChangeName}
      />
    );
  }

  showTitleRender() {
    const { session } = this.props;
    return (
      <>
        <Tooltip title="Double click for rename">
          <Typography className="session-head__header__title" variant="body1" onDoubleClick={e => this.startChangeName(e)}>{session.title}</Typography>
        </Tooltip>
        {this.RepititionsRender()}
        <Tooltip title="Delete Session">
          <div className="session-head__header__delete">
            <DeleteIcon onClick={this.deleteSession} />
          </div>
        </Tooltip>
      </>
    );
  }

  RepititionsRender() {
    const { session } = this.props;
    const { repititions } = session;
    const repititionsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
      <div styles="float: right">
        <form>
          <FormControl>
            <Tooltip title="Replication">
              <Select
                value={repititions}
                onChange={this.changeRepititionsHandler}
                inputProps={{
                  name: 'repititions',
                  id: 'repititions',
                }}
              >
                {repititionsArray.map(key => (
                  <MenuItem value={key} key={key}>{key}</MenuItem>
                ))}
              </Select>
            </Tooltip>
          </FormControl>
        </form>
      </div>
    );
  }

  render() {
    const { showNameEdit } = this.state;
    return (
      <div className="session-head">
        <div className="session-head__header">
          {showNameEdit ? this.renameTitleRender() : this.showTitleRender()}
        </div>
        {this.unequalLevelsRender()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    zoomFactor: state.zoom.zoomFactor,
  };
}

export default connect(mapStateToProps)(SessionHead);
