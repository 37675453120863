import React, { Component } from 'react';
import flow from 'lodash/flow';
import { connect } from 'react-redux';
import {
  Input,
  Paper,
  Typography,
  Fab,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DragSource, DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
import { experimentalDesignProps } from './PropTypes';
import ItemTypes from './ItemTypes';
import Block from './Block';
import SessionHead from './SessionHead';
import ExperimentalDesignColourPicking from './ExperimentalDesignColourPicking';
import { renameExperimentalDesignAction, selectExperimentalDesignAction } from '../../store/experimentalCanvas/experimentalDesign/ExperimentalDesignAction';
import { addNewBlockAction, moveBlockAction } from '../../store/experimentalCanvas/block/BlockAction';
import { addSessionAction } from '../../store/experimentalCanvas/session/SessionAction';

class ExperimentalDesignComponent extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    ed: PropTypes.shape(experimentalDesignProps).isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    selectedExperimentalDesignId: PropTypes.string,
  };

  static defaultProps = {
    selectedExperimentalDesignId: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
    };
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.endChangeName, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.endChangeName, false);
  }

  changeName = (e) => {
    const newName = e.target.value;
    const { dispatch, id } = this.props;
    if (newName.length > 0 && newName.length < 21) {
      dispatch(renameExperimentalDesignAction(id, newName));
    }
  };

  selectExperimentalDesign = () => {
    const { dispatch, id, selectedExperimentalDesignId } = this.props;
    if (id !== selectedExperimentalDesignId && id !== 'ed_new') dispatch(selectExperimentalDesignAction(id));
  }

  endChangeName = (e) => {
    const { id } = this.props;
    const inputId = `input_${id}`;
    if (e.toElement !== undefined && e.toElement.id !== inputId) {
      this.setState({ showEdit: false });
    }
    if (e.key === 'Enter') this.setState({ showEdit: false });
  };

  addSession = () => {
    const { id, dispatch, selectedExperimentalDesignId } = this.props;
    if (id === selectedExperimentalDesignId) dispatch(addSessionAction(id));
  }

  startChangeName() {
    this.setState({ showEdit: true });
  }

  sessionsRender() {
    const { id, ed } = this.props;

    const edSessionsRender = ed.sessions.map(session => (
      <SessionHead
        key={session.id}
        id={session.id}
        experimentalDesignId={id}
        session={session}
      />
    ));

    return (
      <div className="experimental-design__header__sessions">
        {edSessionsRender}
      </div>
    );
  }

  newSessionRender() {
    return (
      <div className="experimental-design__header__new-session">
        <Tooltip title="Add new session">
          <Fab onClick={this.addSession} size="small" color="secondary" aria-label="Add Session">
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }

  titleRender(ed) {
    const { showEdit } = this.state;
    const { id, dispatch } = this.props;

    return (
      <div className="experimental-design__header__title-box">
        <div className="experimental-design__header__title-box__title">
          {showEdit ? (
            <Input
              required
              id={`input_${ed.id}`}
              label="ED Name"
              defaultValue={ed.title}
              onChange={this.changeName}
              onKeyPress={this.endChangeName}
            />
          ) : (
            <Tooltip title="Double click for rename">
              <Typography variant="h6" onDoubleClick={e => this.startChangeName(e)}>{ed.title}</Typography>
            </Tooltip>
          )}
        </div>
        <ExperimentalDesignColourPicking
          dispatch={dispatch}
          experimentalDesignId={id}
          colour={ed.colour}
        />
      </div>

    );
  }

  BlocksRender(blocks, experimentalDesignId) {
    const { connectDropTarget, ed } = this.props;
    const edBlocks = blocks.map((block, i) => (
      <Block
        key={block.id}
        id={block.id}
        block={block}
        edSessions={ed.sessions}
        index={i}
        experimentalDesignId={experimentalDesignId}
        itemType={ItemTypes.BLOCK}
        blockClassName="block-in-experimental-design"
      />
    ));

    return connectDropTarget(
      <div className="experimental-design__drop-zone">
        You can drag the block here
        {edBlocks}
      </div>,
    );
  }

  addBlock() {
    const { dispatch, id } = this.props;
    dispatch(addNewBlockAction(id, undefined, undefined));
  }

  moveBlock(sourceExperimentalDesignId, block) {
    const { id, dispatch } = this.props;
    dispatch(moveBlockAction(id, sourceExperimentalDesignId, block));
  }

  render() {
    const {
      connectDragSource, isDragging, ed, id, selectedExperimentalDesignId,
    } = this.props;
    const { top, left } = ed;
    const width = 290 + ed.sessions.length * 200;
    const opacity = isDragging ? 0 : 1;
    const className = (selectedExperimentalDesignId !== id ? 'experimental-design' : 'experimental-design-selected');
    return connectDragSource(
      <div
        className={className}
        style={{
          opacity, left, top, width,
        }}
      >
        <Paper
          onClick={this.selectExperimentalDesign}
        >
          <div className="experimental-design__header">
            {this.titleRender(ed)}
            {this.sessionsRender()}
            {this.newSessionRender()}
          </div>
          {this.BlocksRender(ed.blocks, id)}
        </Paper>
      </div>,
    );
  }
}

const blockTarget = {
  drop(props, monitor, component) {
    if (!component) {
      return;
    }
    const item = monitor.getItem();
    const { id } = props;
    const { block } = item;
    // console.log(item.message);
    const sourceExperimentalDesignId = item.experimentalDesignId;

    if (item.block.new) {
      component.getDecoratedComponentInstance().addBlock();
      return;
    }

    // this is needed to reset the opacity of the dragged block within its list
    if (id === sourceExperimentalDesignId) {
      component.forceUpdate();
      return;
    }

    if (!item.block.new) {
      block.left = undefined;
      block.top = undefined;
      // only the top block can be of counter balancing type 'BETWEEN'
      if (
        block.counterBalancingMethod === 'BETWEEN'
        && props.ed.blocks.length > 0
      ) { block.counterBalancingMethod = 'LATIN_SQUARE'; }
      component.getDecoratedComponentInstance().moveBlock(sourceExperimentalDesignId, block);
    }
  },
  canDrop(props) {
    if (props.ed.new) return false;
    return true;
  },
};

// eslint-disable-next-line no-shadow
function collectBlock(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

const ExperimentalDesignSource = {
  beginDrag(props) {
    const {
      id, ed, itemType, newItem,
    } = props;
    return {
      id,
      ed,
      itemType,
      newItem,
    };
  },
};

// eslint-disable-next-line no-shadow
function collectExperimentalDesign(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function mapStateToProps(state) {
  return {
    selectedExperimentalDesignId: state.experimentalCanvas.selectedExperimentalDesignId,
  };
}

export default connect(mapStateToProps)(
  flow(
    DragSource(
      ItemTypes.EXPERIMENTAL_DESIGN,
      ExperimentalDesignSource,
      collectExperimentalDesign,
    ),
    DropTarget(ItemTypes.BLOCK, blockTarget, collectBlock),
  )(ExperimentalDesignComponent),
);
