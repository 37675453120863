import { constructSessions } from '../level/LevelHelperAdd';

function updateUnequalLevels(edSessions, newBlock) {
  const tempIvs = newBlock.independentVariables.map((iv) => {
    const tempLevels = iv.levels.map(level => ({ ...level, sessions: constructSessions(edSessions) }));
    return { ...iv, levels: tempLevels };
  });

  return { ...newBlock, independentVariables: tempIvs };
}

function updateSessions(edSessions, newBlock) {
  const newSessions = edSessions.map((edSession) => {
    const newSession = {
      id: edSession.id,
      counterBalancingMethod: 'LATIN_SQUARE',
      replications: 3,
      serial: true,
    };
    return newSession;
  });
  return { ...newBlock, sessions: newSessions };
}

export function addNewBlockToBlocks(blocks, newBlock) {
  const newBlocks = blocks.map(b => b);
  if (newBlock.left !== undefined && newBlock.top !== undefined) {
    const newSessions = [
      {
        id: 'sessions_new',
        counterBalancingMethod: 'LATIN_SQUARE',
        replications: 3,
        serial: true,
      },
    ];
    newBlocks.push({ ...newBlock, sessions: newSessions });
  }
  return newBlocks;
}

export function addNewBlockToEds(eds, experimentalDesignId, newBlock) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      const tempBlocks = ed.blocks;
      let updatedBlock = updateUnequalLevels(ed.sessions, newBlock);
      updatedBlock = updateSessions(ed.sessions, updatedBlock);
      tempBlocks.push(updatedBlock);
      return { ...ed, blocks: tempBlocks };
    }
    return ed;
  });
}
