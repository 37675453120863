import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import { levelProps } from './PropTypes';
import ItemTypes from './ItemTypes';
import {
  deleteLevelAction,
  sortLevelAction,
  renameLevelAction,
} from '../../store/experimentalCanvas/level/LevelAction';
import LevelSession from './LevelSession';

class Level extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    levelClassName: PropTypes.string.isRequired,
    experimentalDesignId: PropTypes.string,
    independentVariableId: PropTypes.string,
    blockId: PropTypes.string,
    level: PropTypes.shape(levelProps).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    edSessions: PropTypes.array.isRequired,
  };

  static defaultProps = {
    experimentalDesignId: undefined,
    blockId: undefined,
    independentVariableId: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
    };
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.endChangeName, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.endChangeName, false);
  }

  changeName = (e) => {
    const newName = e.target.value;
    const {
      dispatch,
      experimentalDesignId,
      blockId,
      independentVariableId,
      id,
    } = this.props;
    dispatch(
      renameLevelAction(
        experimentalDesignId,
        blockId,
        independentVariableId,
        id,
        newName,
      ),
    );
  };

  endChangeName = (e) => {
    const { id } = this.props;
    const inputId = `input_${id}`;
    if (e.toElement !== undefined && e.toElement.id !== inputId) {
      this.setState({ showEdit: false });
    }
    if (e.key === 'Enter') this.setState({ showEdit: false });
  };

  startChangeName() {
    this.setState({ showEdit: true });
  }

  deleteLevel() {
    const {
      dispatch,
      experimentalDesignId,
      blockId,
      independentVariableId,
      id,
    } = this.props;
    dispatch(
      deleteLevelAction(
        experimentalDesignId,
        blockId,
        independentVariableId,
        id,
      ),
    );
  }

  sortLevel(
    experimentalDesignId,
    blockId,
    independentVariableId,
    levelId,
    newIndex,
  ) {
    const { dispatch } = this.props;
    dispatch(
      sortLevelAction(
        experimentalDesignId,
        blockId,
        independentVariableId,
        levelId,
        newIndex,
      ),
    );
  }


  sessionsRender() {
    const {
      edSessions,
      level,
      dispatch,
    } = this.props;

    return (
      <div className="level__sessions">
        {edSessions.map(edSession => (
          // eslint-disable-next-line react/no-array-isndex-key
          <LevelSession
            dispatch={dispatch}
            key={edSession.id}
            unequalLevels={
              level.sessions.filter(
                levelSession => levelSession.id === edSession.id,
              )[0].unequalLevels
            }
          />
        ))}
      </div>
    );
  }

  render() {
    const {
      connectDragSource,
      connectDropTarget,
      isDragging,
      level,
      levelClassName,
      edSessions,
    } = this.props;
    const { showEdit } = this.state;
    const opacity = isDragging ? 0 : 1;
    const { top, left } = level;
    return connectDropTarget(
      connectDragSource(
        <div style={{ opacity, left, top }} className={levelClassName}>
          <div className="level__title">
            {showEdit ? (
              <Input
                required
                id={`input_${level.id}`}
                label="Level Name"
                defaultValue={level.title}
                onChange={this.changeName}
                onKeyPress={this.endChangeName}
                margin="normal"
                variant="outlined"
              />
            ) : (
              <Chip
                onDoubleClick={() => this.startChangeName()}
                label={level.title}
                onDelete={() => this.deleteLevel()}
              />
            )}
          </div>
          {edSessions.length > 0 ? this.sessionsRender() : null }
        </div>,
      ),
    );
  }
}

const LevelSource = {
  beginDrag(props) {
    const {
      id,
      level,
      independentVariableId,
      experimentalDesignId,
      blockId,
      index,
      itemType,
    } = props;
    return {
      id,
      level,
      independentVariableId,
      experimentalDesignId,
      blockId,
      index,
      itemType,
    };
  },
};

const LevelTarget = {
  hover(props, monitor, component) {
    if (!component) return;
    const item = monitor.getItem();
    if (item.itemType !== ItemTypes.LEVEL) return;
    if (
      props.independentVariableId !== item.independentVariableId
      || item.independentVariableId === undefined
    ) { return; }

    const dragIndex = item.index; // the dragged item
    const hoverIndex = props.index; // the not-dragged item
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      findDOMNode(component).style.opacity = 0;
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    component.sortLevel(
      item.experimentalDesignId,
      item.blockId,
      item.independentVariableId,
      item.id,
      hoverIndex,
    );

    item.index = hoverIndex;
  },
};

// eslint-disable-next-line no-shadow
function collectLevel(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

// eslint-disable-next-line no-shadow
function collectObjects(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function mapStateToProps(state) {
  return {
    zoomFactor: state.zoom.zoomFactor,
  };
}

export default connect(mapStateToProps)(
  flow(
    DragSource(ItemTypes.LEVEL, LevelSource, collectLevel),
    DropTarget(ItemTypes.LEVEL, LevelTarget, collectObjects),
  )(Level),
);
