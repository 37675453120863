export const EXPERIMENTAL_DESIGN_MOVE_START = 'EXPERIMENTAL_DESIGN_MOVE_START';
export const EXPERIMENTAL_DESIGN_MOVE_SUCCESS = 'EXPERIMENTAL_DESIGN_MOVE_SUCCESS';

export const ED_ADD_NEW_ED_START = 'ED_ADD_NEW_ED_START';
export const ED_ADD_NEW_ED_SUCCESS = 'ED_ADD_NEW_ED_SUCCESS';

export const ED_REMOVE_ED_START = 'ED_REMOVE_ED_START';
export const ED_REMOVE_ED_SUCCESS = 'ED_REMOVE_ED_SUCCESS';

export const ED_RENAME_ED_START = 'ED_RENAME_ED_START';
export const ED_RENAME_ED_SUCCESS = 'ED_RENAME_ED_SUCCESS';

export const ED_SELECT_ED_START = 'ED_SELECT_ED_START';
export const ED_SELECT_ED_SUCCESS = 'ED_SELECT_ED_SUCCESS';

export const ED_CHANGE_COLOUR_START = 'ED_CHANGE_COLOUR_START';
export const ED_CHANGE_COLOUR_SUCCESS = 'ED_CHANGE_COLOUR_SUCCESS';

export const moveExperimentalDesignAction = (id, left, top) => ({
  type: EXPERIMENTAL_DESIGN_MOVE_START,
  id,
  left,
  top,
});

export const addNewEdToContainerAction = (left, top) => ({
  type: ED_ADD_NEW_ED_START,
  left,
  top,
});

export const removeExperimentalDesignAction = experimentalDesignId => ({
  type: ED_REMOVE_ED_START,
  experimentalDesignId,
});

export const renameExperimentalDesignAction = (
  experimentalDesignId,
  newName,
) => ({
  type: ED_RENAME_ED_START,
  experimentalDesignId,
  newName,
});

export const selectExperimentalDesignAction = experimentalDesignId => ({
  type: ED_SELECT_ED_START,
  experimentalDesignId,
});

export const changeColourOfExperimentalDesignAction = (experimentalDesignId, newColour) => ({
  type: ED_CHANGE_COLOUR_START,
  experimentalDesignId,
  newColour,
});
