export function renameIvInIvs(ivs, independentVariableId, newName) {
  return ivs.map((iv) => {
    if (iv.id === independentVariableId) {
      return { ...iv, title: newName };
    }
    return iv;
  });
}

export function renameIvInBlocks(
  blocks,
  blockId,
  independentVariableId,
  newName,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      return {
        ...b,
        independentVariables: renameIvInIvs(
          b.independentVariables,
          independentVariableId,
          newName,
        ),
      };
    }
    return b;
  });
}

export function renameIvInEds(
  eds,
  edId,
  blockId,
  independentVariableId,
  newName,
) {
  return eds.map((ed) => {
    if (ed.id === edId) {
      return {
        ...ed,
        blocks: renameIvInBlocks(
          ed.blocks,
          blockId,
          independentVariableId,
          newName,
        ),
      };
    }
    return ed;
  });
}
