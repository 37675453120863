import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { ZOOM_UPDATE_ZOOM_SUCCESS } from './ZoomAction';

export const initialState = {
  zoomFactor: 1,
};

export default handleActions({
  [ZOOM_UPDATE_ZOOM_SUCCESS]: (state, action) => immutable(state, {
    zoomFactor: { $set: action.zoomFactor },
  }),
}, initialState);
