import PropTypes from 'prop-types';

const levelProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  top: PropTypes.number,
  left: PropTypes.number,
};

const independentVariableProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  levels: PropTypes.array.isRequired,
  top: PropTypes.number,
  left: PropTypes.number,
};

const blockProps = {
  id: PropTypes.string.isRequired,
  sessions: PropTypes.array.isRequired,
  top: PropTypes.number,
  left: PropTypes.number,
};

const blockSessionProps = {
  id: PropTypes.string.isRequired,
  counterBalancingMethod: PropTypes.string.isRequired,
  replications: PropTypes.number.isRequired,
  serial: PropTypes.bool.isRequired,
};

const experimentalDesignProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  blocks: PropTypes.array.isRequired,
  sessions: PropTypes.array.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};

const sessionProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  repititions: PropTypes.number.isRequired,
  unequalLevels: PropTypes.array.isRequired,
};

const unequalLevel = {
  id: PropTypes.string.isRequired,
};

export {
  levelProps,
  independentVariableProps,
  blockProps,
  experimentalDesignProps,
  sessionProps,
  unequalLevel,
  blockSessionProps,
};
