export const IV_SORT_START = 'IV_SORT_START';
export const IV_SORT_SUCCESS = 'IV_SORT_SUCCESS';

export const IV_REMOVE_START = 'IV_REMOVE_START';
export const IV_REMOVE_SUCCESS = 'IV_REMOVE_SUCCESS';

export const IV_ADD_START = 'IV_ADD_START';
export const IV_ADD_SUCCESS = 'IV_ADD_SUCCESS';

export const IV_MOVE_START = 'IV_MOVE_START';
export const IV_MOVE_SUCCESS = 'IV_MOVE_SUCCESS';

export const IV_RENAME_IV_START = 'IV_RENAME_IV_START';
export const IV_RENAME_IV_SUCCESS = 'IV_RENAME_IV_SUCCESS';

export const addNewIndependentVariableAction = (
  experimentalDesignId,
  blockId,
  left,
  top,
) => ({
  type: IV_ADD_START,
  experimentalDesignId,
  blockId,
  left,
  top,
});

export const removeIndependentVariableAction = (
  experimentalDesignId,
  blockId,
  independentVariableId,
) => ({
  type: IV_REMOVE_START,
  experimentalDesignId,
  blockId,
  independentVariableId,
});

export const moveIndependentVariableAction = (
  targetEdId,
  targetBlockId,
  sourceEdId,
  sourceBlockId,
  independentVariable,
) => ({
  type: IV_MOVE_START,
  targetEdId,
  targetBlockId,
  sourceEdId,
  sourceBlockId,
  independentVariable,
});

export const sortIndependentVariableAction = (
  experimentalDesignId,
  blockId,
  independentVariableId,
  newIndex,
) => ({
  type: IV_SORT_START,
  experimentalDesignId,
  blockId,
  independentVariableId,
  newIndex,
});

export const renameIndependentVariable = (
  experimentalDesignId,
  blockId,
  independentVariableId,
  newName,
) => ({
  type: IV_RENAME_IV_START,
  experimentalDesignId,
  blockId,
  independentVariableId,
  newName,
});
