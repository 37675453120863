const uuidv4 = require('uuid/v4');

export function toggleUnequalLevelInLevel(eds, unequalLevelId, checked) {
  // TODO: simplify!!!
  return eds.map((ed) => {
    const newBlocks = ed.blocks.map((block) => {
      const newIndependentVariables = block.independentVariables.map((iv) => {
        const newLevels = iv.levels.map((level) => {
          const newSessions = level.sessions.map((session) => {
            const newUnequalLevels = session.unequalLevels.map((uel) => {
              if (uel.id === unequalLevelId) return { ...uel, sessionUnequalLevelValue: checked };
              return uel;
            });
            return { ...session, unequalLevels: newUnequalLevels };
          });
          return { ...level, sessions: newSessions };
        });
        return { ...iv, levels: newLevels };
      });
      return { ...block, independentVariables: newIndependentVariables };
    });
    return { ...ed, blocks: newBlocks };
  });
}

export function addUnequalLevelToSession(eds, experimentalDesignId, sessionId) {
  const newUnequalLevelId = `unequalLevelId_${uuidv4()}`;

  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      const newBlocks = ed.blocks.map((block) => {
        const newIndependentVariables = block.independentVariables.map((iv) => {
          const newLevels = iv.levels.map((level) => {
            const newSessions = level.sessions.map((session) => {
              if (session.id === sessionId) {
                const newUnequalLevels = session.unequalLevels;
                newUnequalLevels.push({
                  id: `session_unequal_level_${uuidv4()}`,
                  unequalLevelId: newUnequalLevelId,
                  sessionUnequalLevelValue: true,
                });
                return { ...session, unequalLevels: newUnequalLevels };
              }
              return session;
            });
            return { ...level, sessions: newSessions };
          });
          return { ...iv, levels: newLevels };
        });
        return { ...block, independentVariables: newIndependentVariables };
      });

      const newEdSessions = ed.sessions.map((edSession) => {
        if (edSession.id === sessionId) {
          const newUnequalLevels = edSession.unequalLevels;
          newUnequalLevels.push(newUnequalLevelId);
          return { ...edSession, unequalLevels: newUnequalLevels };
        }
        return edSession;
      });
      return { ...ed, blocks: newBlocks, sessions: newEdSessions };
    }
    return ed;
  });
}

export function deleteUnequalLevelFromSession(eds, unequalLevelId) {
  return eds.map((ed) => {
    const newBlocks = ed.blocks.map((block) => {
      const newIndependentVariables = block.independentVariables.map((iv) => {
        const newLevels = iv.levels.map((level) => {
          const newSessions = level.sessions.map((session) => {
            const newUnequalLevels = session.unequalLevels.filter((uel) => {
              if (uel.unequalLevelId !== unequalLevelId) return uel;
              return undefined;
            });
            return { ...session, unequalLevels: newUnequalLevels };
          });
          return { ...level, sessions: newSessions };
        });
        return { ...iv, levels: newLevels };
      });
      return { ...block, independentVariables: newIndependentVariables };
    });

    const newSessions = ed.sessions.map((session) => {
      const newUnequalLevels = session.unequalLevels.filter((uel) => {
        if (uel !== unequalLevelId) return uel;
        return undefined;
      });
      return { ...session, unequalLevels: newUnequalLevels };
    });
    return { ...ed, blocks: newBlocks, sessions: newSessions };
  });
}
