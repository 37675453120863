export const SESSION_ADD_SESSION_START = 'SESSION_ADD_SESSION_START';
export const SESSION_ADD_SESSION_SUCCESS = 'SESSION_ADD_SESSION_SUCCESS';

export const SESSION_DELETE_SESSION_START = 'SESSION_DELETE_SESSION_START';
export const SESSION_DELETE_SESSION_SUCCESS = 'SESSION_DELETE_SESSION_SUCCESS';

export const SESSION_RENAME_SESSION_START = 'SESSION_RENAME_SESSION_START';
export const SESSION_RENAME_SESSION_SUCCESS = 'SESSION_RENAME_SESSION_SUCCESS';

export const SESSION_TOGGLE_UNEQUAL_LEVEL_START = 'SESSION_TOGGLE_UNEQUAL_LEVEL_START';
export const SESSION_TOGGLE_UNEQUAL_LEVEL_SUCCESS = 'SESSION_TOGGLE_UNEQUAL_LEVEL_SUCCESS';

export const SESSION_SET_REPITITIONS_START = 'SESSION_SET_REPITIONS_START';
export const SESSION_SET_REPITITIONS_SUCCESS = 'SESSION_SET_REPITITIONS_SUCCESS';

export const UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_START = 'UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_START';
export const UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_SUCCESS = 'UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_SUCCESS';

export const UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_START = 'UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_START';
export const UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_SUCCESS = 'UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_SUCCESS';

export const addSessionAction = experimentalDesignId => ({
  type: SESSION_ADD_SESSION_START,
  experimentalDesignId,
});

export const deleteSessionAction = (experimentalDesignId, sessionId) => ({
  type: SESSION_DELETE_SESSION_START,
  experimentalDesignId,
  sessionId,
});

export const renameSessionAction = (experimentalDesignId, sessionId, newName) => ({
  type: SESSION_RENAME_SESSION_START,
  experimentalDesignId,
  sessionId,
  newName,
});

export const toggleSessionUnequalLevelAction = (
  id,
  checked,
) => ({
  type: SESSION_TOGGLE_UNEQUAL_LEVEL_START,
  id,
  checked,
});

export const addUnequalLevelAction = (
  experimentalDesignId,
  sessionId,
) => ({
  type: UNEQUAL_LEVEL_ADD_UNEQUAL_LEVEL_START,
  experimentalDesignId,
  sessionId,
});

export const deleteUnequalLevelAction = unequalLevelId => ({
  type: UNEQUAL_LEVEL_DELETE_UNEQUAL_LEVEL_START,
  unequalLevelId,
});

export const changeRepititionsAction = (sessionId, newRepititions) => ({
  type: SESSION_SET_REPITITIONS_START,
  sessionId,
  newRepititions,
});
