export const LEVEL_ADD_NEW_LEVEL_START = 'LEVEL_ADD_NEW_LEVEL_START';
export const LEVEL_ADD_NEW_LEVEL_SUCCESS = 'LEVEL_ADD_NEW_LEVEL_SUCCESS';
export const LEVEL_MOVE_LEVEL_START = 'LEVEL_MOVE_LEVEL_START';
export const LEVEL_MOVE_LEVEL_SUCCESS = 'LEVEL_MOVE_LEVEL_SUCCESS';
export const LEVEL_DELETE_LEVEL_START = 'LEVEL_DELETE_LEVEL_START';
export const LEVEL_DELETE_LEVEL_SUCCESS = 'LEVEL_DELETE_LEVEL_SUCCESS';
export const LEVEL_SORT_LEVEL_START = 'LEVEL_SORT_LEVEL_START';
export const LEVEL_SORT_LEVEL_SUCCESS = 'LEVEL_SORT_LEVEL_SUCCESS';
export const LEVEL_RENAME_LEVEL_START = 'LEVEL_RENAME_LEVEL_START';
export const LEVEL_RENAME_LEVEL_SUCCESS = 'LEVEL_RENAME_LEVEL_SUCCESS';

export const addNewLevelAction = (
  experimentalDesignId,
  blockId,
  independentVariableId,
  left,
  top,
) => ({
  type: LEVEL_ADD_NEW_LEVEL_START,
  experimentalDesignId,
  blockId,
  independentVariableId,
  left,
  top,
});

export const moveLevelAction = (
  targetEdId,
  targetBlockId,
  targetIvId,
  sourceEdId,
  sourceBlockId,
  sourceIvId,
  level,
) => ({
  type: LEVEL_MOVE_LEVEL_START,
  targetEdId,
  targetBlockId,
  targetIvId,
  sourceEdId,
  sourceBlockId,
  sourceIvId,
  level,
});

export const deleteLevelAction = (
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
) => ({
  type: LEVEL_DELETE_LEVEL_START,
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
});

export const sortLevelAction = (
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
  newIndex,
) => ({
  type: LEVEL_SORT_LEVEL_START,
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
  newIndex,
});

export const renameLevelAction = (
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
  newName,
) => ({
  type: LEVEL_RENAME_LEVEL_START,
  experimentalDesignId,
  blockId,
  independentVariableId,
  levelId,
  newName,
});
