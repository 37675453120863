const uuidv4 = require('uuid/v4');

function constructUnequalLevels(edUnequalLevels) {
  return edUnequalLevels.map((uel) => {
    const id = `session_unequal_level_${uuidv4()}`;
    return {
      id,
      unequalLevelId: uel,
      sessionUnequalLevelValue: false,
    };
  });
}

export function constructSessions(edSessions) {
  const newSessions = [];
  edSessions.map((edSession) => {
    const newSession = {
      id: edSession.id,
      unequalLevels: constructUnequalLevels(edSession.unequalLevels),
    };
    newSessions.push(newSession);
    return undefined;
  });
  return newSessions;
}

export function addLevelToLevels(newLevel, levels) {
  const newLevels = levels.map(l => l);
  if (newLevel.left !== undefined && newLevel.top !== undefined) {
    newLevels.push(newLevel);
  }
  return newLevels;
}

export function addLevelToIndependetVariables(
  newLevel,
  independentVariables,
  independentVariableId,
) {
  return independentVariables.map((iv) => {
    if (iv.id === independentVariableId) {
      const newLevels = iv.levels;
      newLevels.push(newLevel);
      return { ...iv, levels: newLevels };
    }
    return iv;
  });
}

export function addLevelToBlock(
  newLevel,
  blocks,
  blockId,
  independentVariableId,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      return {
        ...b,
        independentVariables: addLevelToIndependetVariables(
          newLevel,
          b.independentVariables,
          independentVariableId,
        ),
      };
    }
    return b;
  });
}

export function addLevelToExperimentalDesigns(
  newLevel,
  eds,
  experimentalDesignId,
  blockId,
  independentVariableId,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: addLevelToBlock(
          { ...newLevel, sessions: constructSessions(ed.sessions) },
          ed.blocks,
          blockId,
          independentVariableId,
        ),
      };
    }
    return ed;
  });
}
