import React from 'react';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Container from './Container';

function ExperimentalDesignCanvas() {
  return (
    <DragDropContextProvider backend={HTML5Backend}>
      <Container />
    </DragDropContextProvider>
  );
}

export default ExperimentalDesignCanvas;
