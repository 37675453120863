export function changeSerialInBlocks(
  blocks,
  blockId,
  sessionId,
  newSerial,
) {
  return blocks.map((b) => {
    if (b.id === blockId) {
      const newSessions = b.sessions.map((session) => {
        if (session.id === sessionId || session.id === undefined) return { ...session, serial: newSerial };
        return session;
      });
      return { ...b, sessions: newSessions };
    }
    return b;
  });
}

export function changeSerialInExperimentalDesigns(
  eds,
  experimentalDesignId,
  blockId,
  sessionId,
  newSerial,
) {
  return eds.map((ed) => {
    if (ed.id === experimentalDesignId) {
      return {
        ...ed,
        blocks: changeSerialInBlocks(
          ed.blocks,
          blockId,
          sessionId,
          newSerial,
        ),
      };
    }
    return ed;
  });
}
