import {
  takeEvery,
  put,
  select,
  call,
} from 'redux-saga/effects';
import {
  IV_SORT_START,
  IV_SORT_SUCCESS,
  IV_ADD_START,
  IV_ADD_SUCCESS,
  IV_REMOVE_START,
  IV_REMOVE_SUCCESS,
  IV_MOVE_START,
  IV_MOVE_SUCCESS,
  IV_RENAME_IV_START,
  IV_RENAME_IV_SUCCESS,
} from './IndependentVariableAction';
import {
  renameIvInIvs,
  renameIvInBlocks,
  renameIvInEds,
} from './IndependentVariableHelperRename';
import {
  addIvToIvs,
  addIvToBlocks,
  addIvToEds,
} from './IndependentVariableHelperAdd';
import {
  removeIvFromIvs,
  removeIvFromBlocks,
  removeIvFromEds,
} from './IndependentVariableHelperRemove';
import {
  sortIvFromEds,
  sortIvFromBlocks,
} from './IndependentVariableHelperSort';
import {
  getAllExperimentalDesigns,
  getAllBlocks,
  getAllIndependentVariables,
} from '../selector';

const uuidv4 = require('uuid/v4');

function* addIndependentVariable(action) {
  const {
    experimentalDesignId,
    blockId,
    left,
    top,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const ivs = yield select(getAllIndependentVariables);
  let ivId = 'iv_';
  ivId += uuidv4();
  const newIv = {
    id: ivId,
    title: 'INDEPENDENT_VARIABLE',
    left,
    top,
    levels: [],
  };

  yield put({
    type: IV_ADD_SUCCESS,
    experimentalDesigns: addIvToEds(eds, experimentalDesignId, blockId, newIv),
    blocks: addIvToBlocks(blocks, blockId, newIv),
    independentVariables: addIvToIvs(ivs, newIv),
  });
}

function* removeIndependentVariable(action) {
  const { experimentalDesignId, blockId, independentVariableId } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const ivs = yield select(getAllIndependentVariables);

  yield put({
    type: IV_REMOVE_SUCCESS,
    experimentalDesigns: removeIvFromEds(
      eds,
      experimentalDesignId,
      blockId,
      independentVariableId,
    ),
    blocks: removeIvFromBlocks(blocks, blockId, independentVariableId),
    independentVariables: removeIvFromIvs(ivs, independentVariableId),
  });
}

function* moveIndependentVariable(action) {
  const {
    targetEdId,
    targetBlockId,
    sourceEdId,
    sourceBlockId,
    independentVariable,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const ivs = yield select(getAllIndependentVariables);
  const independentVariableId = independentVariable.id;

  const tempEds = yield call(
    removeIvFromEds,
    eds,
    sourceEdId,
    sourceBlockId,
    independentVariableId,
  );
  const newEds = yield call(
    addIvToEds,
    tempEds,
    targetEdId,
    targetBlockId,
    independentVariable,
  );

  const tempBlocks = yield call(
    removeIvFromBlocks,
    blocks,
    sourceBlockId,
    independentVariableId,
  );
  const newBlocks = yield call(
    addIvToBlocks,
    tempBlocks,
    targetBlockId,
    independentVariable,
  );

  const tempIvs = yield call(removeIvFromIvs, ivs, independentVariableId);
  const newIvs = yield call(addIvToIvs, tempIvs, independentVariable);

  yield put({
    type: IV_MOVE_SUCCESS,
    experimentalDesigns: newEds,
    blocks: newBlocks,
    independentVariables: newIvs,
  });
}

function* sortIndependentVariable(action) {
  const {
    experimentalDesignId,
    blockId,
    independentVariableId,
    newIndex,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);

  yield put({
    type: IV_SORT_SUCCESS,
    experimentalDesigns: sortIvFromEds(
      eds,
      experimentalDesignId,
      blockId,
      independentVariableId,
      newIndex,
    ),
    blocks: sortIvFromBlocks(blocks, blockId, independentVariableId, newIndex),
  });
}

function* renameIndependentVariable(action) {
  const {
    experimentalDesignId,
    blockId,
    independentVariableId,
    newName,
  } = action;
  const eds = yield select(getAllExperimentalDesigns);
  const blocks = yield select(getAllBlocks);
  const ivs = yield select(getAllIndependentVariables);

  yield put({
    type: IV_RENAME_IV_SUCCESS,
    experimentalDesigns: renameIvInEds(
      eds,
      experimentalDesignId,
      blockId,
      independentVariableId,
      newName,
    ),
    blocks: renameIvInBlocks(blocks, blockId, independentVariableId, newName),
    independentVariables: renameIvInIvs(ivs, independentVariableId, newName),
  });
}

const independentVariableSaga = function* () {
  yield takeEvery(IV_SORT_START, sortIndependentVariable);
  yield takeEvery(IV_RENAME_IV_START, renameIndependentVariable);
  yield takeEvery(IV_ADD_START, addIndependentVariable);
  yield takeEvery(IV_REMOVE_START, removeIndependentVariable);
  yield takeEvery(IV_MOVE_START, moveIndependentVariable);
};

export default independentVariableSaga;
