function constructLevelString(levels, sessionId, unequalLevelId) {
  let levelString = '';
  if (sessionId === undefined && unequalLevelId === undefined) {
    levels.map((level, levelIndex) => {
      levelString += level.title;
      if (levelIndex + 1 < levels.length) levelString += ' , ';
    });
  } else {
    levels.map((level) => {
      // eslint-disable-next-line prefer-destructuring
      const unequalLevels = level.sessions.find(s => s.id === sessionId).unequalLevels;
      const unequalLevel = unequalLevels.find(uel => uel.unequalLevelId === unequalLevelId);

      if (unequalLevel.sessionUnequalLevelValue) {
        if (levelString !== '') levelString += ', ';
        levelString += level.title;
      }
    });
  }

  return levelString;
}

function constructIndependentVariableString(ivs, sessionId, unequalLevelId) {
  let ivsString = '';
  ivs.map((iv, indexIv) => {
    let ivString = `${iv.title} = {`;

    // levels
    const levelString = constructLevelString(iv.levels, sessionId, unequalLevelId);

    ivString += `${levelString}}`;
    if (indexIv + 1 < ivs.length) ivString += ' x ';
    ivsString += ivString;
  });
  return ivsString;
}

function constructBlockString(
  blocks,
  sessionId,
  unequalLevelId,
  sessionIndex,
  unequalLevelIndex,
  sessionTitle,
  repititions,
  lastSession,
  lastUnequalLevel,
) {
  let blockString = '';
  const indendString = '    ';
  let serialCounter = 0;
  blocks.map((block, indexBlock) => {
    let newLine = '';
    if (
      (sessionIndex === 0 && unequalLevelIndex === 0 && indexBlock === 0)
      || (sessionId === undefined && indexBlock === 0)
    ) {
      newLine += '< ';
    } else if (unequalLevelIndex !== 0 && indexBlock === 0) {
      newLine += '  X ';
    } else {
      newLine += indendString;
    }
    if (
      sessionTitle !== undefined
      && repititions !== undefined
      && unequalLevelIndex === 0
      && indexBlock === 0
    ) {
      newLine += `${sessionTitle} = ${repititions} * `;
    }
    let i;
    for (i = 0; i < indexBlock; i++) {
      newLine += indendString;
    }

    // eslint-disable-next-line prefer-destructuring
    const counterBalancingMethod = block.sessions.find(s => s.id === sessionId).counterBalancingMethod;
    if (block.sessions.find(s => s.id === sessionId).serial) {
      newLine += 'Serial(';
      serialCounter += 1;
    }
    newLine += `${counterBalancingMethod}(`;

    // block
    const ivsString = constructIndependentVariableString(
      block.independentVariables,
      sessionId,
      unequalLevelId,
    );

    // eslint-disable-next-line prefer-destructuring
    const replications = block.sessions.find(s => s.id === sessionId).replications;
    newLine += `${ivsString}, ${replications}`;
    if (indexBlock + 1 === blocks.length) {
      for (i = 0; i < indexBlock + 1; i++) {
        newLine += ')';
      }
      for (i = 0; i < serialCounter; i++) {
        newLine += ')';
      }
    } else {
      newLine += ',';
    }
    if (!lastSession && lastUnequalLevel && blocks.length === indexBlock + 1) newLine += ',';
    if (lastSession && lastUnequalLevel && blocks.length === indexBlock + 1) newLine += ' >';
    newLine += '\n';
    blockString += newLine;
  });
  return blockString;
}

export default function transformExperimentalDesignToTsl(ed) {
  // eslint-disable-next-line prefer-destructuring
  const sessions = ed.sessions;
  // eslint-disable-next-line prefer-destructuring
  const blocks = ed.blocks;
  let tsl = '';

  if (sessions.length === 0) {
    tsl += constructBlockString(
      blocks,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }
  sessions.map((session, sessionIndex) => {
    session.unequalLevels.map((unequalLevel, unequalLevelIndex) => {
      const lastSession = (sessions.length === sessionIndex + 1);
      const lastUnequalLevel = (session.unequalLevels.length === unequalLevelIndex + 1);
      tsl += constructBlockString(
        blocks,
        session.id,
        unequalLevel,
        sessionIndex,
        unequalLevelIndex,
        session.title,
        session.repititions,
        lastSession,
        lastUnequalLevel,
      );
    });
  });
  return tsl;
}
